import React, {createContext, useState, useEffect} from 'react';
import {navigate} from 'gatsby';
import qs from 'qs';

import defaultLanguageText from 'i18n/locales/en-US/common';

export const LanguageContext = createContext();

const AMERICAN_ENGLISH = 'en-US';
const LOCALE = 'locale';

// This context provider is passed to any component requiring the context
export const LanguageProvider = ({children, availableLanguages = []}) => {
    const [languageText, setLanguageText] = useState(defaultLanguageText);
    const [currentLang, setCurrentLang] = useState(AMERICAN_ENGLISH);

    // Update the current language if it is read from the query parameters
    useEffect(() => {
        var searchParamObj = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        });

        if (
            searchParamObj[LOCALE] &&
            availableLanguages.includes(searchParamObj[LOCALE])
        ) {
            setCurrentLang(searchParamObj[LOCALE]);
            document.documentElement.lang = searchParamObj[LOCALE];
        }
    }, availableLanguages);

    // Update the page wide language when the user clicks on a new language
    const updateCurrentLang = newLang => {
        const newLanguage = newLang;

        if (newLanguage !== currentLang) {
            if (availableLanguages.includes(newLanguage)) {
                setCurrentLang(newLanguage);

                //Add or update language query parameter
                var searchParamObj = qs.parse(window.location.search, {
                    ignoreQueryPrefix: true,
                });

                searchParamObj[LOCALE] = newLanguage;
                document.documentElement.lang = newLanguage;
                navigate(
                    window.location.pathname +
                        '?' +
                        qs.stringify(searchParamObj)
                );
            } else {
                // The incoming language doesn't match the available languages, print a warning and default to english
                console.error(
                    `New Language: ${newLanguage}, does match available languages: ${availableLanguages}`
                );

                setCurrentLang(AMERICAN_ENGLISH);
                document.documentElement.lang = AMERICAN_ENGLISH;
                //Add or update language query parameter
                var searchParamObj = qs.parse(window.location.search, {
                    ignoreQueryPrefix: true,
                });

                searchParamObj[LOCALE] = AMERICAN_ENGLISH;
                navigate(
                    window.location.pathname +
                        '?' +
                        qs.stringify(searchParamObj)
                );
            }
        }
    };

    // This function combined the default text strings from /i18n/en-US/common.json and
    // combines with the correct language text strings passed from Contentful to produce a JSON object
    // with text keys and matching translated strings values
    const updateTextStrings = (textStrings = []) => {
        let newLanguageText = Object.assign({}, defaultLanguageText);
        let contentfulTextKeys = [];

        textStrings.forEach(({key, text}) => {
            newLanguageText[key] = text;
            contentfulTextKeys.push(key);
        });

        let difference = Object.keys(defaultLanguageText).filter(
            x => !contentfulTextKeys.includes(x)
        );

        // Set an alert if we are missing text keys from Contentful
        if (difference.length > 0) {
            console.error(
                'These text keys are missing from Contentful',
                difference
            );
        }

        setLanguageText(newLanguageText);
    };

    return (
        <LanguageContext.Provider
            value={{
                languageText,
                availableLanguages,
                language: currentLang,
                updateCurrentLang,
                updateTextStrings,
            }}
        >
            {children}
        </LanguageContext.Provider>
    );
};
