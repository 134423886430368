import React, {useRef, useState, useEffect, useContext} from 'react';
import classNames from 'classnames';
import {graphql} from 'gatsby';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import {LanguageContext} from 'hook/LanguageContext';
import {HERO_BLOCK} from 'constants/blockNames';
import useWindowSize from 'hook/useWindowSize';
import {getCloudinaryObject} from 'utils/utilities';

import Header from 'components/header';
import CTAButton from 'components/ctaButton';

import DPPWMHeroBlock from './DPPWMHeroBlock';
import MyStrengthCompleteHeroBlock from './MyStrengthCompleteHeroBlock';
import MyStrengthDigitalHeroBlock from './MyStrengthDigitalHeroBlock';
import WPHFHeroBlock from './WPHFHeroBlock';
import LivongoComHeroBlock from './LivongoComHeroBlock';

import * as styles from './HeroBlock.module.scss';

const BADGE_LOCATIONS = {
    LEFT: 'LEFT',
    LEFT_BOTTOM: 'LEFT BOTTOM',
    BOTTOM: 'BOTTOM',
    RIGHT_BOTTOM: 'RIGHT BOTTOM',
};

const BACKGROUND_IMAGE_ORIENTATION = {
    WHOLE_SCREEN: 'WHOLE_SCREEN',
    RIGHT: 'RIGHT',
};

const CUSTOMIZATIONS = {
    NORMAL: 'NORMAL',
    LARGE_TEXT: 'LARGE_TEXT',
    DPPWM_STYLE: 'DPPWM_STYLE',
    GEN_STYLE: 'GEN_STYLE',
    LIVONGO_COM_CUSTOMIZATION: 'LIVONGO_COM_CUSTOMIZATION',
    MYSTRENGTH_DIGITAL_CUSTOMIZATION: 'MYSTRENGTH_DIGITAL_CUSTOMIZATION',
    MYSTRENGTH_COMPLETE_CUSTOMIZATION: 'MYSTRENGTH_COMPLETE_CUSTOMIZATION',
    MEDICARE_ADVANTAGE_STYLE: 'MEDICARE_ADVANTAGE_STYLE',
    WPHF_CUSTOMIZATION: 'WPHF_CUSTOMIZATION',
};

const DEFAULT_NO_COST_BADGE = 'NONE';

const HeroBlock = props => {
    const {
        // title,
        // content,
        // noCostBadge,
        // ctaButton,
        // backgroundImage,
        // backgroundImageOrientation = BACKGROUND_IMAGE_ORIENTATION.WHOLE_SCREEN,
        // backgroundImageTopPadding = 0,
        // backgroundImageBackgroundColor = '#FFFFFF',
        // mobileBackgroundImage,
        // coBrandingLogo,
        // coBrandingLogoWidth,
        // secondCobrandingLogo,
        // extraTall = false,
        // contactDescription,
        customization = CUSTOMIZATIONS.NORMAL,
        // headerCustomization,
    } = props;

    // For running different experiments or different versions of the Hero
    switch (customization) {
        case CUSTOMIZATIONS.DPPWM_STYLE:
            return <DPPWMHeroBlock {...props} />;
        case CUSTOMIZATIONS.MEDICARE_ADVANTAGE_STYLE:
            return (
                <DPPWMHeroBlock
                    {...props}
                    className={styles.medicareAdvantageContainer}
                />
            );
        case CUSTOMIZATIONS.LIVONGO_COM_CUSTOMIZATION:
            return <LivongoComHeroBlock {...props} />;
        case CUSTOMIZATIONS.MYSTRENGTH_DIGITAL_CUSTOMIZATION:
            return <MyStrengthDigitalHeroBlock {...props} />;
        case CUSTOMIZATIONS.MYSTRENGTH_COMPLETE_CUSTOMIZATION:
            return <MyStrengthCompleteHeroBlock {...props} />;
        case CUSTOMIZATIONS.WPHF_CUSTOMIZATION:
            return <WPHFHeroBlock {...props} />;
    }

    return (
        <div id={HERO_BLOCK}>
            <div className={styles.desktopView}>
                <DesktopHeroBlock {...props} />
            </div>
            <div className={styles.mobileView}>
                <MobileHeroBlock {...props} />
            </div>
        </div>
    );
};

const DesktopHeroBlock = props => {
    const {
        title,
        content,
        noCostBadge = DEFAULT_NO_COST_BADGE,
        ctaButton,
        backgroundImage,
        backgroundImageOrientation = BACKGROUND_IMAGE_ORIENTATION.WHOLE_SCREEN,
        backgroundImageTopPadding = 0,
        backgroundImageBackgroundColor = '#FFFFFF',
        // mobileBackgroundImage,
        coBrandingLogo,
        coBrandingLogoWidth,
        secondCobrandingLogo,
        extraTall = false,
        // contactDescription,
        customization = CUSTOMIZATIONS.NORMAL,
        headerCustomization,
    } = props;
    const {width: windowWidth} = useWindowSize();
    const ref = useRef(null);
    const [containerHeight, setContainerHeight] = useState(null);
    useEffect(() => {
        if (ref.current) {
            setContainerHeight(ref.current.offsetHeight);
        }
    }, [ref.current]);

    let backgroundImageObj = getCloudinaryObject(backgroundImage);
    let rootStyle = {
        backgroundImage: `url(${backgroundImageObj.url})`,
    };
    let containerStyle = {};

    if (
        backgroundImageOrientation === BACKGROUND_IMAGE_ORIENTATION.WHOLE_SCREEN
    ) {
        rootStyle['backgroundSize'] = 'cover';
        rootStyle['backgroundPositionY'] = 0;

        const heroCurrentHeight =
            backgroundImageObj.height *
            (windowWidth / backgroundImageObj.width);

        // As the container stretches out and the image stretches out, this cuts off some of the unneed empty space at the top
        if (heroCurrentHeight > containerHeight) {
            rootStyle['backgroundPositionY'] =
                -(backgroundImageTopPadding / 100) * heroCurrentHeight;
        }

        // For an image that is right aligned, use 'contain' if the width of the container is wider then image otherwise use 'cover
    } else if (
        backgroundImageOrientation === BACKGROUND_IMAGE_ORIENTATION.RIGHT
    ) {
        rootStyle['backgroundSize'] =
            windowWidth / containerHeight >
            backgroundImageObj.width / backgroundImageObj.height
                ? 'contain'
                : 'cover';

        // For images where the left edge of the image is a different color then white
        rootStyle['backgroundColor'] = backgroundImageBackgroundColor;
    }

    const cobrandingLogoObj = getCloudinaryObject(coBrandingLogo);
    const secondCobrandingLogoObj = getCloudinaryObject(secondCobrandingLogo);

    return (
        <div
            ref={ref}
            className={classNames(styles.heroImage, {
                [styles.largeText]: customization === CUSTOMIZATIONS.LARGE_TEXT,
                [styles.genStyle]: customization === CUSTOMIZATIONS.GEN_STYLE,
            })}
            style={rootStyle}
        >
            <div
                className={classNames(styles.container, {
                    [styles.extraTall]: extraTall,
                })}
                style={containerStyle}
            >
                <Header
                    cobrandingLogoUrl={cobrandingLogoObj?.url}
                    cobrandingLogoAltText={cobrandingLogoObj?.name}
                    cobrandingLogoWidth={coBrandingLogoWidth}
                    customization={headerCustomization}
                />
                <div className={styles.leftRightContainer}>
                    <div className={styles.leftSide}>
                        {secondCobrandingLogoObj && (
                            <img
                                src={secondCobrandingLogoObj?.url}
                                alt={secondCobrandingLogoObj?.name}
                            ></img>
                        )}
                        <h1 className={styles.title}>
                            {renderRichText(title)}
                        </h1>
                        <div className={styles.contentContainer}>
                            {noCostBadge === BADGE_LOCATIONS.LEFT && (
                                <NoCostBadge
                                    medium={
                                        customization ===
                                            CUSTOMIZATIONS.LARGE_TEXT ||
                                        customization ===
                                            CUSTOMIZATIONS.GEN_STYLE
                                    }
                                />
                            )}
                            {content && (
                                <div className={styles.content}>
                                    {renderRichText(content)}
                                </div>
                            )}
                        </div>

                        <div className={styles.bottomRow}>
                            <div className={styles.leftContainer}>
                                {ctaButton && (
                                    <CTAButton
                                        id="ctaHero"
                                        isBold={true}
                                        {...ctaButton}
                                    />
                                )}
                            </div>
                            <div>
                                {noCostBadge ===
                                    BADGE_LOCATIONS.LEFT_BOTTOM && (
                                    <NoCostBadge large />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={styles.rightSide}></div>
                </div>

                {(noCostBadge === BADGE_LOCATIONS.BOTTOM ||
                    noCostBadge === BADGE_LOCATIONS.RIGHT_BOTTOM) && (
                    <NoCostBadge
                        className={classNames({
                            [styles.bottom]:
                                noCostBadge === BADGE_LOCATIONS.BOTTOM,
                            [styles.rightBottom]:
                                noCostBadge === BADGE_LOCATIONS.RIGHT_BOTTOM,
                        })}
                        large
                    />
                )}
            </div>
        </div>
    );
};

const MobileHeroBlock = props => {
    const {
        title,
        content,
        noCostBadge = DEFAULT_NO_COST_BADGE,
        ctaButton,
        backgroundImage,
        // backgroundImageOrientation = BACKGROUND_IMAGE_ORIENTATION.WHOLE_SCREEN,
        // backgroundImageTopPadding = 0,
        // backgroundImageBackgroundColor = '#FFFFFF',
        mobileBackgroundImage,
        coBrandingLogo,
        coBrandingLogoWidth,
        secondCobrandingLogo,
        // extraTall = false,
        // contactDescription,
        // customization = CUSTOMIZATIONS.NORMAL,
        headerCustomization,
        signInButton,
    } = props;
    let backgroundImageObj =
        getCloudinaryObject(mobileBackgroundImage) ||
        getCloudinaryObject(backgroundImage);
    let rootStyle = {
        backgroundImage: `url(${backgroundImageObj.url})`,
    };

    const cobrandingLogoObj = getCloudinaryObject(coBrandingLogo);
    const secondCobrandingLogoObj = getCloudinaryObject(secondCobrandingLogo);

    return (
        <div id={HERO_BLOCK} className={styles.mobileHeroBlock}>
            <div className={classNames(styles.marginDiv)}>
                <Header
                    cobrandingLogoUrl={cobrandingLogoObj?.url}
                    cobrandingLogoAltText={cobrandingLogoObj?.name}
                    cobrandingLogoWidth={coBrandingLogoWidth}
                    customization={headerCustomization}
                    secondCobrandingLogoUrl={secondCobrandingLogoObj?.url}
                    secondCobrandingAltText={secondCobrandingLogoObj?.name}
                    signInButton={signInButton}
                    isMobile
                />
                <h1 className={styles.title}>{renderRichText(title)}</h1>
            </div>
            <div
                className={styles.mobileHeroImage}
                style={{backgroundImage: `url(${backgroundImageObj.url})`}}
            >
                {noCostBadge !== DEFAULT_NO_COST_BADGE && (
                    <div className={classNames(styles.marginDiv)}>
                        <NoCostBadge medium />
                    </div>
                )}
            </div>
            {ctaButton && (
                <div className={styles.mobileCtaContainer}>
                    <CTAButton id="ctaHero" isBold={true} {...ctaButton} />
                </div>
            )}
            <div className={classNames(styles.marginDiv)}>
                {content && (
                    <div className={styles.content}>
                        {renderRichText(content)}
                    </div>
                )}
            </div>
        </div>
    );
};

const NoCostBadge = props => {
    const {className, large, medium} = props;
    const {languageText} = useContext(LanguageContext);

    return (
        <div
            className={classNames(className, styles.costBadge, {
                [styles.large]: large,
                [styles.medium]: medium,
            })}
        >
            <div className={styles.badgePrice}>
                {languageText['zero-dollars']}
            </div>
            <div className={styles.badgeRecurance}>
                {large || medium
                    ? languageText['per-month']
                    : languageText['slash-month']}
            </div>
        </div>
    );
};

export default HeroBlock;

export const query = graphql`
    fragment HeroBlock on ContentfulHeroBlock {
        id
        name
        title {
            raw
        }
        content {
            raw
        }
        contactInformation {
            raw
        }
        signInButton {
            ...SignInButton
        }
        ctaButton {
            ...CTAButton
        }
        noCostBadge
        backgroundImage {
            ...CloudinaryImage
        }
        backgroundImageOrientation
        backgroundImageTopPadding
        backgroundImageBackgroundColor
        mobileBackgroundImage {
            ...CloudinaryImage
        }
        coBrandingLogo {
            ...CloudinaryImage
        }
        coBrandingLogoWidth
        secondCobrandingLogo {
            ...CloudinaryImage
        }
        extraTall
        customization
        headerCustomization
        starbadgeType {
            ...StarbadgeType
        }
    }
`;
