import React, {useRef, useState, useEffect, useContext} from 'react';
import classNames from 'classnames';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import {LanguageContext} from 'hook/LanguageContext';
import {HERO_BLOCK} from 'constants/blockNames';
import useWindowSize from 'hook/useWindowSize';
import {getCloudinaryImageUrl, getCloudinaryObject} from 'utils/utilities';

import Header from 'components/header';
import CTAButton from 'components/ctaButton';

import * as styles from './HeroBlock.module.scss';

// the smaller one
const defaultBackgroundImageUrl =
    'https://res.cloudinary.com/livongo-health-inc/image/upload/v1602577068/contentful/Hero%20Backgrounds/hero1_bg_dwk4c2.png';

const BADGE_LOCATIONS = {
    LEFT: 'LEFT',
    LEFT_BOTTOM: 'LEFT BOTTOM',
    BOTTOM: 'BOTTOM',
    RIGHT_BOTTOM: 'RIGHT BOTTOM',
};

const BACKGROUND_IMAGE_ORIENTATION = {
    WHOLE_SCREEN: 'WHOLE_SCREEN',
    RIGHT: 'RIGHT',
};

const CUSTOMIZATIONS = {
    NORMAL: 'NORMAL',
    BH_FEPBLUE_VARH: 'BH_FEPBLUE_VARH',
    BH_FEPBLUE_VARJ: 'BH_FEPBLUE_VARJ',
    DPPWM_UHC: 'DPPWM_STYLE',
    DPPWM_UHC_VARA: 'DPPWM_UHC_VARA_STYLE',
};

const DPPWMHeroBlock = props => {
    const {
        title,
        content,
        noCostBadge,
        ctaButton,
        backgroundImage,
        backgroundImageOrientation,
        mobileBackgroundImage,
        coBrandingLogo,
        coBrandingLogoWidth,
        extraTall = false,
        contactInformation,
        headerCustomization,
        className,
    } = props;

    const {width: windowWidth, isMobile} = useWindowSize();
    const ref = useRef(null);
    const [containerHeight, setContainerHeight] = useState(null);

    useEffect(() => {
        if (ref.current) {
            setContainerHeight(ref.current.offsetHeight);
        }
    }, [ref.current]);

    let backgroundImageObj =
        isMobile && mobileBackgroundImage
            ? getCloudinaryObject(mobileBackgroundImage)
            : getCloudinaryObject(backgroundImage);
    let rootStyle = {
        backgroundImage: `url(${backgroundImageObj.url})`,
    };
    let containerStyle = {};

    // For mobile, push either image to the bottom and make the container bottom padding match the size of the image height
    if (isMobile) {
        rootStyle['backgroundSize'] = 'contain';
        rootStyle['backgroundPosition'] = 'bottom';
        containerStyle['paddingBottom'] =
            (windowWidth / backgroundImageObj.width) *
            backgroundImageObj.height;

        // For an image that stretch the whole screen, use background size 'cover'
    } else if (
        backgroundImageOrientation === BACKGROUND_IMAGE_ORIENTATION.WHOLE_SCREEN
    ) {
        rootStyle['backgroundSize'] = 'cover';

        // For an image that is right aligned, use 'contain' if the width of the container is wider then image otherwise use 'cover
    } else if (
        backgroundImageOrientation === BACKGROUND_IMAGE_ORIENTATION.RIGHT
    ) {
        rootStyle['backgroundSize'] =
            windowWidth / containerHeight >
            backgroundImageObj.width / backgroundImageObj.height
                ? 'contain'
                : 'cover';
    }

    const cobrandingLogoObj = getCloudinaryObject(coBrandingLogo);

    return (
        <>
            <Header
                cobrandingLogoUrl={cobrandingLogoObj?.url}
                cobrandingLogoAltText={cobrandingLogoObj?.name}
                cobrandingLogoWidth={coBrandingLogoWidth}
                customization={headerCustomization}
                isDPPWM={true}
            />
            <div
                id={HERO_BLOCK}
                ref={ref}
                className={styles.dppHeroImage}
                style={rootStyle}
            >
                <div
                    className={classNames(styles.dppContainer, className, {
                        [styles.extraTall]: extraTall,
                    })}
                    style={containerStyle}
                >
                    <div className={styles.dppLeftRightContainer}>
                        <div className={styles.leftSide}>
                            <h1 className={styles.title}>
                                {renderRichText(title)}
                            </h1>
                            <div className={styles.contentContainer}>
                                {noCostBadge === BADGE_LOCATIONS.LEFT && (
                                    <NoCostBadge />
                                )}
                                {content && (
                                    <div className={styles.content}>
                                        {renderRichText(content)}
                                    </div>
                                )}
                            </div>

                            <div className={styles.leftContainer}>
                                {ctaButton && (
                                    <CTAButton
                                        id="ctaHero"
                                        isBold={false}
                                        isMobile={isMobile}
                                        isDPPWM={true}
                                        {...ctaButton}
                                    />
                                )}
                            </div>
                            {contactInformation && (
                                <div className={styles.contactInformation}>
                                    {renderRichText(contactInformation)}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const NoCostBadge = props => {
    const {className, large, medium} = props;
    const {languageText} = useContext(LanguageContext);

    return (
        <div
            className={classNames(className, styles.costBadge, {
                [styles.large]: large,
                [styles.medium]: medium,
            })}
        >
            <div className={styles.badgePrice}>
                {languageText['zero-dollars']}
            </div>
            <div className={styles.badgeRecurance}>
                {large || medium
                    ? languageText['per-month']
                    : languageText['slash-month']}
            </div>
        </div>
    );
};

export default DPPWMHeroBlock;
