import React, {useContext} from 'react';
import classNames from 'classnames';
import {Link, animateScroll as scroll} from 'react-scroll';

import {
    HERO_BLOCK,
    DEVICE_BLOCK,
    TESTIMONIALS_BLOCK,
} from 'constants/blockNames';
import {GlobalContext} from 'hook/GlobalContext';
import {LanguageContext} from 'hook/LanguageContext';
import useWindowSize from 'hook/useWindowSize';
import {phoneNumberClicked, translationClicked} from 'utils/mixpanel-utils';

import * as styles from './FloatingFooter.module.scss';

const FloatingFooter = props => {
    const {languageText} = useContext(LanguageContext);
    const {contactNumber, contactPhoneURL} = useContext(GlobalContext);
    const {isMobile} = useWindowSize();

    const defaultFooterLinks = [
        {
            block: HERO_BLOCK,
            title: languageText['overview'],
        },
        {
            block: DEVICE_BLOCK,
            title: languageText['key-features'],
        },
        {
            block: TESTIMONIALS_BLOCK,
            title: languageText['testimonials'],
        },
    ];

    const {footerLinks = defaultFooterLinks, ctaButton} = props;

    const onClickPhone = () => {
        phoneNumberClicked();
    };
    
    return (
        <div className={classNames(styles.floatingFooter)}>
            {!isMobile && (
                <div className={classNames(styles.footerLinksItem)}>
                    {footerLinks.map((link, i) => (
                        <div key={i}>
                            <Link
                                activeClass="active"
                                to={link.block}
                                spy={true}
                                smooth={true}
                                duration={500}
                            >
                                {link.title}
                            </Link>
                        </div>
                    ))}
                </div>
            )}

            {contactNumber && (
                <div className={styles.phoneContact} onClick={onClickPhone}>
                    <a href={contactPhoneURL}>
                        {isMobile
                            ? languageText['call'][0].toUpperCase() +
                              languageText['call'].slice(1)
                            : contactNumber}
                    </a>
                </div>
            )}
        </div>
    );
};

export default FloatingFooter;
