import React, {useState, useContext} from 'react';
import classNames from 'classnames';
import {renderRichText} from 'gatsby-source-contentful/rich-text';
import {graphql} from 'gatsby';

import {LanguageContext} from 'hook/LanguageContext';
import {removeNullFromProps} from 'utils/utilities';
import {footerUrls} from 'constants/shortLinks';

import FloatingFooter from './floatingFooter';
import BHFooter from './BHFooter';
import DPPWMFooter from './DPPWMFooter';
import GENFooter from './GENFooter';
import LivongoComFooter from './LivongoComFooter';
import MemberPolicyModal from './MemberPolicyModal';

import * as styles from './Footer.module.scss';

const CUSTOMIZATIONS = {
    NORMAL: 'NORMAL',
    BH_STYLE: 'BH_STYLE',
    MEDICARE_ADVANTAGE_STYLE: 'MEDICARE_ADVANTAGE_STYLE',
    GEN_TLD_STYLE: 'GEN_TLD_STYLE',
    MYSTRENGTH_STYLE: 'MYSTRENGTH_STYLE',
    LIVONGO_COM_STYLE: 'LIVONGO_COM_STYLE',
};

const Footer = props => {
    const {
        plNumber,
        disclaimer,
        ctaButton,
        contactNumber,
        memberCommunicationPolicy,
        footerLinks,
        customization = CUSTOMIZATIONS.NORMAL,
    } = removeNullFromProps(props);

    const {language, languageText} = useContext(LanguageContext);
    const [showPolicy, setShowPolicy] = useState(false);

    const openClosePolicy = () => {
        setShowPolicy(!showPolicy);
    };

    switch (customization) {
        case CUSTOMIZATIONS.BH_STYLE:
            return <BHFooter {...props} />;
        case CUSTOMIZATIONS.MEDICARE_ADVANTAGE_STYLE:
            return <DPPWMFooter {...props} />;
        case CUSTOMIZATIONS.GEN_TLD_STYLE:
            return <GENFooter {...props} />;
        case CUSTOMIZATIONS.LIVONGO_COM_STYLE:
            return <LivongoComFooter {...props} />;
    }

    return (
        <footer
            role="contentinfo"
            className={classNames(styles.footer, {
                [styles.ctaButtonShowing]: ctaButton,
            })}
        >
            <FloatingFooter
                phone={contactNumber}
                footerLinks={footerLinks}
                ctaButton={ctaButton}
            />

            <div className={classNames(styles.container)}>
                <div className={styles.flexContainer}>
                    <div className={styles.leftContainer}>
                        {footerUrls.map((obj, i) => {
                            return (
                                <a
                                    key={i}
                                    className={styles.link}
                                    href={obj[language]}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <span>{languageText[obj.name]}</span>
                                </a>
                            );
                        })}
                        <a
                            className={styles.link}
                            href="https://livongo.eightfold.ai/careers"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <span>{languageText['careers']}</span>
                        </a>
                    </div>
                    <div className={styles.rightContainer}>
                        <span>
                            {new Date().getFullYear() +
                                ' ' +
                                languageText[
                                    'year-at-livongo-all-rights-reserved'
                                ]}
                        </span>
                        <span>{plNumber}</span>
                    </div>
                </div>

                {disclaimer && (
                    <div className={styles.disclaimer}>
                        {renderRichText(disclaimer)}
                    </div>
                )}

                <MemberPolicyModal
                    openClosePolicy={openClosePolicy}
                    showPolicy={showPolicy}
                    memberCommunicationPolicy={memberCommunicationPolicy}
                />
            </div>
        </footer>
    );
};

export default Footer;

export const query = graphql`
    fragment Footer on ContentfulFooter {
        name
        disclaimer {
            raw
        }
        footerLinks {
            title
            block
        }
        ctaButton {
            buttonText
            backgroundColor
            customization
            size
        }
        customization
    }
`;
