import React from 'react';
import classNames from 'classnames';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import {WHAT_YOU_GET_BLOCK} from 'constants/blockNames';
import {getCloudinaryImageUrl} from 'utils/utilities';

import BackgroundBlock from 'components/backgroundBlock';
import CTAButton from 'components/ctaButton';

import * as styles from './WhatYouGetBlock.module.scss';

const DPPWMWhatYouGetBlock = props => {
    const {
        title,
        subtitle,
        listItems,
        showNoCostBadge,
        ctaButton,
        contactInformation,
        backgroundBlock,
    } = props;

    return (
        <BackgroundBlock id={WHAT_YOU_GET_BLOCK} {...backgroundBlock}>
            <div className={classNames(styles.dppContainer)}>
                {title && (
                    <div className={styles.title}>
                        {title}{' '}
                        {subtitle && (
                            <span className={styles.subtitle}>{subtitle}</span>
                        )}
                    </div>
                )}

                <div className={styles.listItemsContainer}>
                    <ul className={styles.listItems}>
                        {listItems.map((item, i) => (
                            <li key={i} className={styles.listItem}>
                                {item}
                            </li>
                        ))}
                    </ul>
                </div>

                {showNoCostBadge && (
                    <div className={styles.costBadge}>
                        <div className={styles.badgePrice}>$0</div>
                        <div className={styles.badgeRecurance}>per month</div>
                    </div>
                )}

                {ctaButton && (
                    <div className={styles.dppCtaBtn}>
                        <CTAButton
                            id="ctaWhatYouGet"
                            isBold
                            {...ctaButton}
                            isDPPWM={true}
                        />
                    </div>
                )}

                {contactInformation && (
                    <div className={styles.dppContact}>
                        {renderRichText(contactInformation)}
                    </div>
                )}
            </div>
        </BackgroundBlock>
    );
};

export default DPPWMWhatYouGetBlock;
