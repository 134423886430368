import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {LanguageContext} from 'hook/LanguageContext';
import {convertEnvironmentLivongoLink} from 'utils/utilities';
import {signInClicked} from 'utils/mixpanel-utils';

import * as styles from './Header.module.scss';

const LIVONGO_LOGO =
    'https://assets.livongo.com/image/upload/v1641508544/landing-pages/header-block/livongoTH_logo.png';
const BLUE_LINE =
    'https://assets.livongo.com/image/upload/v1611338394/landing-pages/header-block/Nav-blueline.png';

const CUSTOMIZATIONS = {
    NO_LIVONGO_LOGO: 'NO_LIVONGO_LOGO',
    CLIENT_LOGO_ON_LEFT: 'CLIENT_LOGO_ON_LEFT',
    CLIENT_LOGO_ON_LEFT_WITH_VERTICAL_LINE:
        'CLIENT_LOGO_ON_LEFT_WITH_VERTICAL_LINE',
    CLIENT_LOGO_ON_RIGHT: 'CLIENT_LOGO_ON_RIGHT',
    CLIENT_LOGO_ON_RIGHT_WITH_VERTICAL_LINE:
        'CLIENT_LOGO_ON_RIGHT_WITH_VERTICAL_LINE',
};

const DPPWMHeader = props => {
    const {
        cobrandingLogoUrl,
        cobrandingLogoAltText,
        cobrandingLogoWidth,
        customization,
    } = props;

    const {languageText} = useContext(LanguageContext);
    const loginURL = convertEnvironmentLivongoLink(process.env.LOGIN_URL);

    const onClickSignIn = () => {
        signInClicked();

        window.location = loginURL;
    };

    return (
        <header className={styles.dppStyleHeader}>
            <div
                className={classNames(styles.logoContainer, {
                    [styles.clientLogoOnLeft]:
                        customization === CUSTOMIZATIONS.CLIENT_LOGO_ON_LEFT ||
                        customization ===
                            CUSTOMIZATIONS.CLIENT_LOGO_ON_LEFT_WITH_VERTICAL_LINE,
                })}
            >
                {customization !== CUSTOMIZATIONS.NO_LIVONGO_LOGO && (
                    <img
                        className={styles.dppStyleLogo}
                        src={LIVONGO_LOGO}
                        alt="Livongo Logo"
                    />
                )}
                {cobrandingLogoUrl &&
                    customization !== CUSTOMIZATIONS.CLIENT_LOGO_ON_LEFT &&
                    customization !== CUSTOMIZATIONS.CLIENT_LOGO_ON_RIGHT &&
                    customization !== CUSTOMIZATIONS.NO_LIVONGO_LOGO && (
                        <img
                            className={styles.dppStyleLogoPipe}
                            src={BLUE_LINE}
                            alt="Blue Line"
                        />
                    )}
                {cobrandingLogoUrl && (
                    <div className={styles.dppStyleCoBrandingLogo}>
                        <img
                            className={classNames(
                                styles.coBrandingLogo,
                                styles.dppStyleLogo
                            )}
                            style={{width: `${cobrandingLogoWidth}%`}}
                            src={cobrandingLogoUrl}
                            alt={cobrandingLogoAltText}
                        ></img>
                    </div>
                )}
            </div>

            <button
                className={styles.dppStyleSignInButton}
                onClick={onClickSignIn}
            >
                {languageText['sign-in']}
            </button>
        </header>
    );
};

DPPWMHeader.propTypes = {
    cobrandingLogoUrl: PropTypes.string,
    cobrandingLogoAltText: PropTypes.string,
};

DPPWMHeader.defaultProps = {
    isDPPWM: false,
};

export default DPPWMHeader;
