import React from 'react';
import classNames from 'classnames';
import Slider from 'react-slick';
import {graphql} from 'gatsby';

import {CAROUSEL_BLOCK} from 'constants/blockNames';
import {getCloudinaryImageUrl, removeNullFromProps} from 'utils/utilities';
import useWindowSize from 'hook/useWindowSize';
import CarouselBlockMobile from './CarouselBlockMobile';

import CTAButton from 'components/ctaButton';

import * as styles from './CarouselBlock.module.scss';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CUSTOMIZATIONS = {
    MYSTRENGTH_STYLE: 'MYSTRENGTH_STYLE',
};

const CarouselBlock = props => {
    const {carouselCards, customization} = removeNullFromProps(props);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: true,
        className: styles.slider,
        vertical: true,
        verticalSwiping: true,
        adaptiveHeight: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 8000,
        fade: true,
        appendDots: dots => (
            <div>
                <ul className={styles.verticalDots}> {dots} </ul>
            </div>
        ),
    };

    const windowSize = useWindowSize();
    const isMobile = windowSize.isMobile || windowSize.width === 768;

    if (isMobile) {
        return <CarouselBlockMobile {...props} />;
    }

    return (
        <div id={CAROUSEL_BLOCK} className={classNames(styles.root)}>
            <div className={styles.container}>
                <Slider {...settings}>
                    {carouselCards.map((carouselCard, i) => {
                        const imageURL = getCloudinaryImageUrl(
                            carouselCard.carouselImage
                        );

                        if (
                            !carouselCard.title &&
                            !carouselCard.subtitle &&
                            carouselCard.content &&
                            !imageURL
                        ) {
                            return null;
                        }

                        return (
                            <div key={carouselCard.id}>
                                <div
                                    className={classNames(
                                        styles.flexContainer,
                                        {
                                            [styles.mystrengthStyle]:
                                                customization ===
                                                CUSTOMIZATIONS.MYSTRENGTH_STYLE,
                                        }
                                    )}
                                >
                                    {imageURL && (
                                        <img
                                            className={styles.imageContainer}
                                            src={imageURL}
                                            alt={
                                                carouselCard.carouselImage.name
                                            }
                                        ></img>
                                    )}

                                    <div className={styles.carouselCard}>
                                        {carouselCard.subtitle && (
                                            <div className={styles.subtitle}>
                                                {carouselCard.subtitle}
                                            </div>
                                        )}
                                        {carouselCard.title && (
                                            <div className={styles.title}>
                                                {carouselCard.title}
                                            </div>
                                        )}
                                        {carouselCard.content && (
                                            <div className={styles.content}>
                                                {carouselCard.content.content}
                                            </div>
                                        )}
                                        {carouselCard.ctaButton && (
                                            <CTAButton
                                                {...carouselCard.ctaButton}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </Slider>
            </div>
        </div>
    );
};

export default CarouselBlock;

export const query = graphql`
    fragment CarouselBlock on ContentfulCarouselBlock {
        id
        name
        customization
        carouselCards {
            id
            name
            title
            subtitle
            ctaButton {
                ...CTAButton
            }
            content {
                content
            }
            carouselImage {
                ...CloudinaryImage
            }
        }
    }
`;
