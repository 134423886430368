import React, {createContext} from 'react';
import {liveChatClicked} from 'utils/mixpanel-utils';

export const ChatContext = createContext();

export const ChatProvider = ({children}) => {
    // Handles when a user clicks on the Live Chat button
    const onClickChat = () => {
        liveChatClicked();

        const chatButtons = document.getElementsByClassName("helpButtonEnabled");
        if(chatButtons.length) {
            chatButtons[0].click();
        }
    };
    return (
        <ChatContext.Provider value={{onClickChat}}>
            {children}
        </ChatContext.Provider>
    );
};
