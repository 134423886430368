import React, {forwardRef} from 'react';
import classNames from 'classnames';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import {removeNullFromProps} from 'utils/utilities';

import * as styles from './FloatingBanner.module.scss';

const WHITE = '#FFFFFF';
const BLACK = '#000000';
const BABY_BLUE = '#67b5ff';

const FloatingBanner = forwardRef((props, ref) => {
    const {
        backgroundColorHex = WHITE,
        textColorHex = BLACK,
        content,
        ctaText,
        ctaLink,
        ctaColorHex = BABY_BLUE,
        stickToTop,
    } = removeNullFromProps(props);

    const rootStyle = {
        backgroundColor: backgroundColorHex,
        color: textColorHex,
    };

    return (
        <div
            ref={ref}
            className={classNames(styles.root, {
                [styles.stickToTop]: stickToTop,
            })}
            style={rootStyle}
        >
            <div className={styles.container}>
                {renderRichText(content)}
                {ctaLink && (
                    <span
                        style={{
                            color: ctaColorHex,
                        }}
                    >
                        {' '}
                        <a
                            href={ctaLink}
                            target="_blank"
                            style={{color: ctaColorHex}}
                        >
                            {ctaText}
                        </a>
                    </span>
                )}
            </div>
        </div>
    );
});

export default FloatingBanner;
