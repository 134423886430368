// extracted by mini-css-extract-plugin
export var content = "FocusBlock-module--content--HYU4x";
export var contentItem = "FocusBlock-module--content-item--jXEyJ";
export var ctaBtn = "FocusBlock-module--cta-btn--gs11V";
export var ctaButton = "FocusBlock-module--cta-button--khuBq";
export var mystrengthFocusBlockContainer = "FocusBlock-module--mystrength-focus-block-container--nwSn2";
export var point = "FocusBlock-module--point--JjfRl";
export var pointsContainer = "FocusBlock-module--points-container--qPntu";
export var pointsItem = "FocusBlock-module--points-item--YBPcu";
export var root = "FocusBlock-module--root--WH-07";
export var subtitle = "FocusBlock-module--subtitle--xhvIj";
export var title = "FocusBlock-module--title--UWvHp";
export var titleItem = "FocusBlock-module--title-item--TO2DJ";