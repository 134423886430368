import React, {useContext} from 'react';
import classNames from 'classnames';
import {graphql} from 'gatsby';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import {PROGRAM_BENEFITS_BLOCK} from 'constants/blockNames';
import {getCloudinaryObject} from 'utils/utilities';

import BackgroundBlock from 'components/backgroundBlock';
import CTAButton from 'components/ctaButton';

import ProgramBenefitsHowItWorksBlock from './ProgramBenefitsHowItWorksBlock';
import ProgramBenefitsMystrength from './ProgramBenefitsMystrength';
import ProgramBenefitsWPHF from './ProgramBenefitsWPHF';
import blueCheckbox from './images/blue-checkbox.svg';
import * as styles from './ProgramBenefitsBlock.module.scss';

const CUSTOMIZATIONS = {
    HOW_IT_WORKS_CUSTOMIZATION: 'HOW_IT_WORKS_CUSTOMIZATION',
    MYSTRENGTH_COMPLETE_CUSTOMIZATION: 'MYSTRENGTH_COMPLETE_CUSTOMIZATION',
    WPHF_CUSTOMIZATION: 'WPHF_CUSTOMIZATION',
};

const ProgramBenefitsBlock = props => {
    const {
        title,
        content,
        image,
        benefits,
        ctaButton,
        disclaimer,
        backgroundBlock,
        customization,
    } = props;

    switch (customization) {
        case CUSTOMIZATIONS.HOW_IT_WORKS_CUSTOMIZATION:
            return <ProgramBenefitsHowItWorksBlock {...props} />;
        case CUSTOMIZATIONS.MYSTRENGTH_COMPLETE_CUSTOMIZATION:
            return <ProgramBenefitsMystrength {...props} />;
        case CUSTOMIZATIONS.WPHF_CUSTOMIZATION:
            return <ProgramBenefitsWPHF {...props} />;
    }

    const imageObject = getCloudinaryObject(image);

    return (
        <BackgroundBlock
            id={PROGRAM_BENEFITS_BLOCK}
            containerClassName={styles.programBenefitsBackgroundBlock}
            {...backgroundBlock}
        >
            <div className={classNames(styles.root)}>
                <div className={styles.leftSide}>
                    {title && <div className={styles.title}>{title}</div>}
                    {content && (
                        <div className={styles.content}>
                            {renderRichText(content)}
                        </div>
                    )}
                    {image && (
                        <img
                            src={imageObject.url}
                            alt={image.name}
                            className={styles.image}
                        />
                    )}
                </div>
                <div className={styles.rightSide}>
                    {benefits?.map((benefit, i) => (
                        <div key={i} className={styles.bulletPointContainer}>
                            <ul className={styles.bulletPointUL}>
                                <li className={styles.bulletPoint}>
                                    <img
                                        src={blueCheckbox}
                                        className={styles.blueCheckbox}
                                    />
                                    {benefit.programName}
                                </li>
                                <li className={styles.subBulletPointContainer}>
                                    <ul className={styles.subBulletPointUL}>
                                        {benefit.benefits &&
                                            benefit.benefits.map(
                                                (subBulletPoint, j) => (
                                                    <div
                                                        key={j}
                                                        className={
                                                            styles.subBulletPoint
                                                        }
                                                    >
                                                        <span
                                                            className={
                                                                styles.dot
                                                            }
                                                        >
                                                            •
                                                        </span>
                                                        {subBulletPoint}
                                                    </div>
                                                )
                                            )}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    ))}

                    {ctaButton && (
                        <CTAButton
                            id="ctaWhatYouGet"
                            className={styles.ctaButton}
                            {...ctaButton}
                        />
                    )}
                    {disclaimer && (
                        <div className={styles.disclaimer}>
                            {renderRichText(disclaimer)}
                        </div>
                    )}
                </div>
            </div>
        </BackgroundBlock>
    );
};

export default ProgramBenefitsBlock;

export const query = graphql`
    fragment ProgramBenefitsBlock on ContentfulProgramBenefitsBlock {
        name
        title
        content {
            raw
        }
        benefits {
            programName
            benefits
        }
        ctaButton {
            ...CTAButton
        }
        disclaimer {
            raw
        }
        image {
            ...CloudinaryImage
        }
        backgroundBlock {
            ...BackgroundBlock
        }
        customization
    }
`;
