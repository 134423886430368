import React, {useContext} from 'react';
import classNames from 'classnames';
import {graphql} from 'gatsby';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import {LanguageContext} from 'hook/LanguageContext';
import {WHAT_YOU_GET_BLOCK} from 'constants/blockNames';

import BackgroundBlock from 'components/backgroundBlock';
import CTAButton from 'components/ctaButton';

import DPPWMWhatYouGetBlock from './DPPWMWhatYouGetBlock';
import {removeNullFromProps} from 'utils/utilities';

import * as styles from './WhatYouGetBlock.module.scss';

const CUSTOMIZATIONS = {
    DPPWM_STYLE: 'DPPWM_STYLE',
    IMAGE_ON_LEFT: 'IMAGE_ON_LEFT',
};

const CHECK_MARK_COLOR = {
    GREEN: 'GREEN',
    BLUE: 'BLUE',
};

const DISCLAIMER_SIDE = {
    LEFT: 'left',
    RIGHT: 'right',
    BOTTOM: 'bottom',
};

const FONT_FAMILY = {
    DEFAULT: 'Default',
    EFFRA: 'Effra',
};

const WhatYouGetBlock = props => {
    const {
        subtitle,
        title,
        listItems,
        checkMarkColor = CHECK_MARK_COLOR.GREEN,
        showNoCostBadge,
        ctaButton,
        disclaimer,
        backgroundBlock,
        disclaimerSide = DISCLAIMER_SIDE.BOTTOM,
        customization,
        fontFamily = FONT_FAMILY.DEFAULT,
    } = removeNullFromProps(props);

    if (customization === CUSTOMIZATIONS.DPPWM_STYLE) {
        return <DPPWMWhatYouGetBlock {...props} />;
    }

    const {languageText} = useContext(LanguageContext);

    const checkMarkClasses = classNames(styles.listItem, {
        [styles[checkMarkColor]]: checkMarkColor,
    });

    const disclaimerClasses = classNames(styles.disclaimer, {
        [styles[disclaimerSide]]: disclaimerSide,
    });

    return (
        <BackgroundBlock id={WHAT_YOU_GET_BLOCK} {...backgroundBlock}>
            <div
                setfont={fontFamily}
                className={classNames(styles.root, {
                    [styles.imageOnLeft]:
                        customization === CUSTOMIZATIONS.IMAGE_ON_LEFT,
                })}
            >
                <div className={styles.titlesContainer}>
                    {subtitle && (
                        <div className={styles.subtitle}>{subtitle}</div>
                    )}
                    {title && <div className={styles.title}>{title}</div>}
                </div>

                <ul className={styles.listItems}>
                    {listItems.map((item, i) => (
                        <li key={i} className={checkMarkClasses}>
                            {item}
                        </li>
                    ))}
                </ul>

                {showNoCostBadge && (
                    <div className={styles.costBadge}>
                        <div className={styles.badgePrice}>
                            {languageText['zero-dollars']}
                        </div>
                        <div className={styles.badgeRecurance}>
                            {languageText['per-month']}
                        </div>
                    </div>
                )}

                {ctaButton && (
                    <CTAButton
                        id="ctaWhatYouGet"
                        isBold
                        className={styles.ctaButton}
                        {...ctaButton}
                    />
                )}

                {disclaimer && (
                    <div className={disclaimerClasses}>
                        {renderRichText(disclaimer)}
                    </div>
                )}
            </div>
        </BackgroundBlock>
    );
};

export default WhatYouGetBlock;

export const query = graphql`
    fragment WhatYouGetBlock on ContentfulWhatYouGetBlock {
        id
        name
        subtitle
        title
        listItems
        checkMarkColor
        fontFamily
        disclaimerSide
        contactInformation {
            raw
        }
        ctaButton {
            ...CTAButton
        }
        showNoCostBadge
        disclaimer {
            raw
        }
        backgroundBlock {
            ...BackgroundBlock
        }
        customization
    }
`;
