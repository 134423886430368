// extracted by mini-css-extract-plugin
export var appStore = "AppBlock-module--app-store--r5Quc";
export var btnItems = "AppBlock-module--btn-items--dBtrU";
export var content = "AppBlock-module--content--4s3T5";
export var context = "AppBlock-module--context---Azhf";
export var ctaButton = "AppBlock-module--cta-button--na87E";
export var genStyle = "AppBlock-module--gen-style--9wU0B";
export var mystrengthStyle = "AppBlock-module--mystrength-style--pRy4N";
export var playStore = "AppBlock-module--play-store--ZJhOl";
export var root = "AppBlock-module--root--IEIaB";
export var subtitle = "AppBlock-module--subtitle--cZ51n";
export var title = "AppBlock-module--title--ChdPY";