// extracted by mini-css-extract-plugin
export var backgroundImage = "TestimonialsBlock-module--background-image--Iw89-";
export var cardItem = "TestimonialsBlock-module--card-item--ei7oG";
export var content = "TestimonialsBlock-module--content--6vwvp";
export var dppMemberImage = "TestimonialsBlock-module--dpp-member-image--2vf+R";
export var dppStyle = "TestimonialsBlock-module--dpp-style--zJvd4";
export var largerTextStyle = "TestimonialsBlock-module--larger-text-style--du5sJ";
export var last3Large = "TestimonialsBlock-module--last-3-large--isAvX";
export var last3Small = "TestimonialsBlock-module--last-3-small--cmkAY";
export var livongoComStyle = "TestimonialsBlock-module--livongo-com-style--HVjYU";
export var memberDescription = "TestimonialsBlock-module--member-description--6ZDL4";
export var memberDetails = "TestimonialsBlock-module--member-details--OzumO";
export var memberImage = "TestimonialsBlock-module--member-image--bIZ6v";
export var memberName = "TestimonialsBlock-module--member-name--kdAMB";
export var mystrength = "TestimonialsBlock-module--mystrength--E-v9E";
export var offset = "TestimonialsBlock-module--offset--hUFHp";
export var root = "TestimonialsBlock-module--root--zy77V";
export var subtitle = "TestimonialsBlock-module--subtitle--ozUvT";
export var testimonial = "TestimonialsBlock-module--testimonial--eatxt";
export var testimonialCards = "TestimonialsBlock-module--testimonial-cards--XPNSC";
export var testimonialsDisclaimer = "TestimonialsBlock-module--testimonials-disclaimer--WX9a2";
export var title = "TestimonialsBlock-module--title--RJDYZ";
export var wphfContainer = "TestimonialsBlock-module--wphf-container--CDKFx";