import React, {useContext} from 'react';
import classNames from 'classnames';
import {renderRichText} from 'gatsby-source-contentful/rich-text';
import {LanguageContext} from 'hook/LanguageContext';
import useWindowSize from 'hook/useWindowSize';
import {removeNullFromProps} from 'utils/utilities';
import {footerUrls} from 'constants/shortLinks';

import FloatingFooter from './floatingFooter';

import * as styles from './Footer.module.scss';

const GENFooter = props => {
    const {
        plNumber,
        disclaimer,
        ctaButton,
        // memberCommunicationPolicy,
        footerLinks,
    } = removeNullFromProps(props);

    const {isMobile} = useWindowSize();
    const {language, languageText} = useContext(LanguageContext);

    return (
        <footer
            role="contentinfo"
            className={classNames(styles.footer, {
                [styles.ctaButtonShowing]: ctaButton,
            })}
        >
            <FloatingFooter footerLinks={footerLinks} ctaButton={ctaButton} />
            <div className={classNames(styles.container)}>
                <div className={styles.flexContainer}>
                    <div className={styles.leftContainer}>
                        {footerUrls.map((obj, i) => {
                            return (
                                <a
                                    key={i}
                                    className={styles.link}
                                    href={obj[language]}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {languageText[obj.name]}
                                </a>
                            );
                        })}
                    </div>
                    <div className={styles.rightContainer}>
                        <span>
                            {new Date().getFullYear() +
                                ' ' +
                                languageText[
                                    'year-at-livongo-all-rights-reserved'
                                ]}
                        </span>
                        <span>{plNumber}</span>
                    </div>
                </div>

                {disclaimer && (
                    <div className={styles.disclaimer}>
                        {renderRichText(disclaimer)}
                    </div>
                )}
            </div>
        </footer>
    );
};

export default GENFooter;
