import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {getCloudinaryImageUrl} from 'utils/utilities';
import useWindowSize from 'hook/useWindowSize';
import {removeNullFromProps} from 'utils/utilities';

import MobileBackgroundBlock from './MobileBackgroundBlock';
import * as styles from './BackgroundBlock.module.scss';

const BACKGROUND_IMAGE_ORIENTATION = {
    LEFT: 'Left',
    RIGHT: 'Right',
    COVER: 'Cover',
    RIGHT_INSIDE_CONTAINER: 'Right Inside Container',
    LEFT_INSIDE_CONTAINER: 'Left Inside Container',
};

const CUSTOMIZATIONS = {
    MOBILE_AT_LARGE_BREAKPOINT: 'MOBILE_AT_LARGE_BREAKPOINT',
};

const WHITE = '#FFFFFF';
const BLACK = '#000000';
const LARGE_BREAKPOINT = 1025;
const CONTAINER_TOP_BOTTOM_PADDING = 60 + 25;

const BackgroundBlock = props => {
    const {
        id,
        className,
        containerClassName,
        children,
        backgroundColorHex = WHITE,
        textColorHex = BLACK,
        backgroundImage,
        orientation: backgroundImageOrientation = BACKGROUND_IMAGE_ORIENTATION.COVER,
        backgroundImageMaxWidth,
        backgroundImagePadding = 0,
        backgroundGradient,
        desktopMinimumHeight,
        desktopTopPadding = 0,
        desktopContainerTopPadding,
        desktopContainerBottomPadding,
        maxContainerWidth,
        customization,
    } = removeNullFromProps(props);

    const {isMobile, width} = useWindowSize();

    if (
        isMobile ||
        (customization === CUSTOMIZATIONS.MOBILE_AT_LARGE_BREAKPOINT &&
            width < LARGE_BREAKPOINT)
    ) {
        return <MobileBackgroundBlock {...props}></MobileBackgroundBlock>;
    }

    let rootStyle = {};
    let backgroundStyle = {};
    let containerStyle = {};

    const backgroundImageURL = getCloudinaryImageUrl(backgroundImage) || '';

    if (backgroundImageURL) {
        switch (backgroundImageOrientation) {
            case BACKGROUND_IMAGE_ORIENTATION.LEFT:
                backgroundStyle = {
                    backgroundImage: `url(${backgroundImageURL})`,
                    backgroundPosition: `left ${backgroundImagePadding}px center`,
                    backgroundSize: backgroundImageMaxWidth
                        ? `${backgroundImageMaxWidth}px auto`
                        : null,
                };
                break;
            case BACKGROUND_IMAGE_ORIENTATION.RIGHT:
                backgroundStyle = {
                    backgroundImage: `url(${backgroundImageURL})`,
                    backgroundPosition: `right ${backgroundImagePadding}px center`,
                    backgroundSize: backgroundImageMaxWidth
                        ? `${backgroundImageMaxWidth}px auto`
                        : null,
                };
                break;
            case BACKGROUND_IMAGE_ORIENTATION.COVER:
                backgroundStyle = {
                    backgroundImage: `url(${backgroundImageURL})`,
                    backgroundPosition: `center`,
                    backgroundSize: 'cover',
                };
                break;
            case BACKGROUND_IMAGE_ORIENTATION.RIGHT_INSIDE_CONTAINER:
                containerStyle = {
                    backgroundImage: `url(${backgroundImageURL})`,
                    backgroundPosition: `right ${backgroundImagePadding}px center`,
                    backgroundSize: backgroundImageMaxWidth
                        ? `${backgroundImageMaxWidth}px auto`
                        : null,
                };
                break;
            case BACKGROUND_IMAGE_ORIENTATION.LEFT_INSIDE_CONTAINER:
                containerStyle = {
                    backgroundImage: `url(${backgroundImageURL})`,
                    backgroundPosition: `left ${backgroundImagePadding}px center`,
                    backgroundSize: backgroundImageMaxWidth
                        ? `${backgroundImageMaxWidth}px auto`
                        : null,
                };
                break;
        }
    }

    if (backgroundGradient) {
        rootStyle['backgroundImage'] = backgroundGradient;
    } else {
        rootStyle['backgroundColor'] = backgroundColorHex;
    }

    rootStyle['color'] = textColorHex;
    backgroundStyle['paddingTop'] = desktopTopPadding;

    if (desktopMinimumHeight) {
        const rootMinHeight = desktopMinimumHeight - desktopTopPadding;
        rootStyle['minHeight'] = rootMinHeight;
        backgroundStyle['minHeight'] = rootMinHeight;
        containerStyle['minHeight'] =
            rootMinHeight - CONTAINER_TOP_BOTTOM_PADDING;
    }

    if (maxContainerWidth) {
        containerStyle['maxWidth'] = maxContainerWidth;
    }

    if (desktopContainerTopPadding !== undefined) {
        containerStyle['paddingTop'] = desktopContainerTopPadding;
    }

    if (desktopContainerBottomPadding !== undefined) {
        containerStyle['paddingBottom'] = desktopContainerBottomPadding;
    }

    return (
        <div
            id={id}
            style={rootStyle}
            className={classNames(styles.root, className)}
        >
            <div
                className={classNames(styles.imgItem, className)}
                style={backgroundStyle}
            >
                <div
                    className={classNames(styles.container, containerClassName)}
                    style={containerStyle}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};

BackgroundBlock.propTypes = {
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    textColorHex: PropTypes.string,
    backgroundColorHex: PropTypes.string,
    backgroundImage: PropTypes.object,
    backgroundGradient: PropTypes.string,
    orientation: PropTypes.string,
    backgroundImageMaxWidth: PropTypes.number,
    backgroundImagePadding: PropTypes.number,
    desktopMinimumHeight: PropTypes.number,
    desktopTopPadding: PropTypes.number,
    desktopContainerTopPadding: PropTypes.number,
    desktopContainerBottomPadding: PropTypes.number,
    maxContainerWidth: PropTypes.number,
};

export default BackgroundBlock;
