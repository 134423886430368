
import React, {useContext} from 'react';
import * as styles from './Footer.module.scss'; 
import classNames from 'classnames';
import {renderRichText} from 'gatsby-source-contentful/rich-text';
import {LanguageContext} from 'hook/LanguageContext';



const MemberPolicyModal = ({
    openClosePolicy, 
    showPolicy,
    memberCommunicationPolicy,
}) => {
    const {languageText} = useContext(LanguageContext);

        return (
        <div
            className={classNames(styles.modalGrayOut, {
                [styles.open]: showPolicy,
            })}
            onClick={openClosePolicy}
        >
            <div
                className={styles.memberCommunicationPolicy}
                onClick={event => {
                    // Allows for the outside container to close the modal
                    event.stopPropagation();
                }}
            >
                <div className={styles.title}>
                    {languageText['livongo-member-communication-policy']}
                    <div
                        className={styles.closeButton}
                        onClick={openClosePolicy}
                    >
                        X
                    </div>
                </div>
                {memberCommunicationPolicy ? (
                    <div className={styles.memberCommunicationPolicyBody}>
                        {renderRichText(memberCommunicationPolicy)}
                    </div>
                ) : (
                    <div>
                        {/* Fallback if we don't get the translated Member Communication Policy from Contentful*/}
                        <div>
                            By accepting this Policy, I hereby give my consent
                            for Livongo Health, Inc. (“Livongo”) to communicate
                            with me through the methods outlined in this
                            document. I understand that these communications may
                            involve the use and disclosure of protected health
                            information (“PHI”) about me to carry out treatment,
                            payment and health care operations (“TPO”). I
                            further understand that I may change my
                            communication preferences with Livongo at any time.
                        </div>
    
                        <br />
                        <div>Telephone</div>
                        <div>
                            With this consent, Livongo may call my home number,
                            mobile number or other alternative number and leave
                            a message on voicemail in reference to any items
                            that assist Livongo in carrying out TPO, such as
                            reminders, insurance items, shipment and delivery of
                            supplies, health profile updates and any calls
                            pertaining to my care, including, but not limited
                            to, conversations with Livongo Certified Diabetes
                            Educators.
                        </div>
    
                        <br />
                        <div>Text</div>
                        <div>
                            With this consent, Livongo may contact me via
                            unencrypted SMS text messages in reference to any
                            items that assist Livongo in carrying out TPO, such
                            as reminders, insurance items, shipment and delivery
                            of supplies, health profile updates and any
                            communications pertaining to my care, including text
                            messages exchanged with Livongo Certified Diabetes
                            Educators and automated text message campaigns.
                        </div>
                        <br />
                        <div>Email</div>
                        <div>
                            With this consent, Livongo may contact me via
                            unencrypted email in reference to any items that
                            assist Livongo in carrying out TPO, such as
                            reminders, insurance items, shipment and delivery of
                            supplies, health profile updates and any
                            communications pertaining to my care, including
                            information exchanged with Livongo Certified
                            Diabetes Educators and email message campaigns.
                        </div>
                    </div>
                )}
            </div>
        </div>
        )};
  
  
 export default MemberPolicyModal;
 