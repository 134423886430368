import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {LanguageContext} from 'hook/LanguageContext';
import {convertMyStrengthEnvironment} from 'utils/utilities';
import {signInClicked} from 'utils/mixpanel-utils';

import * as styles from './Header.module.scss';

const MYSTRENGTH_LOGO =
    'https://assets.livongo.com/image/upload/v1642018525/landing-pages/header-block/myStrength_th_logo.png';
const MYSTRENTH_LOGIN_URL = 'https://web-ui.mystrength.livongo.com/login';

const CUSTOMIZATIONS = {
    NO_LIVONGO_LOGO: 'NO_LIVONGO_LOGO',
    CLIENT_LOGO_ON_LEFT: 'CLIENT_LOGO_ON_LEFT',
    CLIENT_LOGO_ON_LEFT_WITH_VERTICAL_LINE:
        'CLIENT_LOGO_ON_LEFT_WITH_VERTICAL_LINE',
    CLIENT_LOGO_ON_RIGHT: 'CLIENT_LOGO_ON_RIGHT',
    CLIENT_LOGO_ON_RIGHT_WITH_VERTICAL_LINE:
        'CLIENT_LOGO_ON_RIGHT_WITH_VERTICAL_LINE',
};

const MyStrengthHeader = props => {
    const {
        cobrandingLogoUrl,
        cobrandingLogoAltText,
        cobrandingLogoWidth,
        customization,
    } = props;

    const {languageText} = useContext(LanguageContext);
    const myStrengthURL = convertMyStrengthEnvironment(MYSTRENTH_LOGIN_URL);

    const onClickSignIn = () => {
        signInClicked();

        window.location = myStrengthURL;
    };

    return (
        <header className={styles.mystrengthStyleHeaderContainer}>
            <div
                className={classNames(styles.logoContainer, {
                    [styles.clientLogoOnLeft]:
                        customization === CUSTOMIZATIONS.CLIENT_LOGO_ON_LEFT ||
                        customization ===
                            CUSTOMIZATIONS.CLIENT_LOGO_ON_LEFT_WITH_VERTICAL_LINE,
                })}
            >
                {customization !== CUSTOMIZATIONS.NO_LIVONGO_LOGO && (
                    <img
                        className={styles.dppStyleLogo}
                        src={MYSTRENGTH_LOGO}
                        alt="Livongo Logo"
                    />
                )}

                {cobrandingLogoUrl && (
                    <div className={styles.dppStyleCoBrandingLogoContainer}>
                        <img
                            className={classNames(
                                styles.coBrandingLogo,
                                styles.dppStyleLogo
                            )}
                            style={{width: `${cobrandingLogoWidth}%`}}
                            src={cobrandingLogoUrl}
                            alt={cobrandingLogoAltText}
                        ></img>
                    </div>
                )}
            </div>

            <button
                className={classNames(styles.mystrengthBtnItem, {
                    [styles.white]: true,
                })}
                onClick={onClickSignIn}
            >
                {languageText['log-in']}
            </button>
        </header>
    );
};

MyStrengthHeader.propTypes = {
    cobrandingLogoUrl: PropTypes.string,
    cobrandingLogoAltText: PropTypes.string,
};

export default MyStrengthHeader;
