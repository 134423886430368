import React, {useContext} from 'react';
import {renderRichText} from 'gatsby-source-contentful/rich-text';
import {GlobalContext} from 'hook/GlobalContext';
import htmlStringReactParser from 'html-react-parser';
import {INLINES} from '@contentful/rich-text-types';

import {removeNullFromProps} from 'utils/utilities';

import * as styles from './BannerMessage.module.scss';

const WHITE = '#FFFFFF';
const NAVY_BLUE = '#00325e';
const CTA_TEXT = 'Click here for more information';

const BannerMessage = (props, ref) => {
    const {
        backgroundColorHex = NAVY_BLUE,
        content,
        ctaText = CTA_TEXT,
        extendedContent,
        textColorHex = WHITE,
    } = removeNullFromProps(props);

    const rootStyle = {
        backgroundColor: backgroundColorHex,
        color: textColorHex,
    };
    const {
        contactNumber,
        contactPhoneURL,
        physicianAttestationForm,
    } = useContext(GlobalContext);

    const options = {
        replace: ({attribs, children}) => {
            if (!attribs) {
                return;
            }

            switch (attribs.id) {
                case 'phoneNumber':
                    return <a href={contactPhoneURL}>{contactNumber}</a>;
                case 'physicianForm':
                    return (
                        <a href={physicianAttestationForm}>
                            {'Physician Attestation Form'}
                        </a>
                    );
                default:
                    break;
            }
        },
    };

    const contentOptions = {
        renderNode: {
            [INLINES.HYPERLINK]: (node, children) => {
                const {uri} = node.data;
                return (
                    <a href={uri} class={styles.expandLabel}>
                        {children}
                    </a>
                );
            },
        },
    };

    return (
        <div className={styles.root} style={rootStyle}>
            <div className={styles.container}>
                <input type="checkbox" id="expandmsg" />
                <div className={styles.content}>
                    {content ? renderRichText(content, contentOptions) : ''}
                </div>

                <label class={styles.expandLabel} for="expandmsg">
                    {ctaText}
                </label>

                <div className={styles.message}>
                    {htmlStringReactParser(
                        extendedContent?.childMarkdownRemark?.html,
                        options
                    )}
                </div>
            </div>
        </div>
    );
};

export default BannerMessage;
