import React from 'react';
import {graphql} from 'gatsby';
import { renderRichText } from "gatsby-source-contentful/rich-text";

import {PERSONALIZED_SOLUTION_BLOCK} from 'constants/blockNames';
import useWindowSize from 'hook/useWindowSize';
import {getCloudinaryImageUrl} from 'utils/utilities';

import BackgroundBlock from 'components/backgroundBlock';
import CTAButton from 'components/ctaButton';

import * as styles from './PersonalizedSolutionBlock.module.scss';
import classNames from 'classnames';

const CUSTOMIZATIONS = {
    MYSTRENGTH_STYLE: 'MYSTRENGTH_STYLE',
};

const PersonalizedSolutionBlock = props => {
    const {
        title,
        subtitle,
        content,
        backgroundBlock,
        ctaButton,
        solutions,
        customization = CUSTOMIZATIONS.NORMAL,
    } = props;
    const windowSize = useWindowSize();
    const isMobile = windowSize.isMobile;

    return (
        <BackgroundBlock id={PERSONALIZED_SOLUTION_BLOCK} {...backgroundBlock}>
                <div className={classNames({[styles.mystrengthContainer]: customization === CUSTOMIZATIONS.MYSTRENGTH_STYLE})}>
                    {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
                    {title && (<div className={classNames(styles.title)}>{title}</div>)}

                    <div className={styles.contentRow}>
                        {ctaButton && (
                            <CTAButton
                                id="ctaHeroPersonalizedBlock"
                                className={styles.ctaButton}
                                isBold={true}
                                isMobile={isMobile}
                                {...ctaButton}
                            />
                        )}
                        {content && (
                            <div className={classNames(styles.content)}>
                                    <div>{renderRichText(content)}</div>
                            </div>
                        )}
                    </div>

                    <div className={styles.solutionsContainer}>
                        {solutions.map((solution, i) => (
                            <div key={i} className={styles.solutionItem}>
                                <img
                                    src={getCloudinaryImageUrl(solution.image)}
                                    alt={solution.image.name}
                                    width="62"
                                    height="62"
                                    />

                                {solution.title && (
                                    <div className={classNames(styles.title)}>{solution.title}</div>)}

                                {solution.content && (
                                    <div className={classNames(styles.content)}>{solution.content}</div>)}
                            </div>
                        ))}
                    </div>
                </div>
        </BackgroundBlock>
    );
};

export default PersonalizedSolutionBlock;

export const query = graphql`
    fragment PersonalizedSolutionBlock on ContentfulPersonalizedSolutionBlock {
        name
        title
        subtitle
        content {
            raw
        }
        ctaButton {
            buttonText
            url
            backgroundColor
            size
        }
        solutions {
            name
            title
            content
            image {
                ...CloudinaryImage
            }
        }
        backgroundBlock {
            ...BackgroundBlock
        }
        customization
    }
`;
