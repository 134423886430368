import React from 'react';
import classNames from 'classnames';

import {DEVICE_BLOCK} from 'constants/blockNames';
import {getCloudinaryImageUrl} from 'utils/utilities';

import BackgroundBlock from 'components/backgroundBlock';

import * as styles from './DeviceBlock.module.scss';

const DEFAULT_LIST_ITEM_ICON_SIZE = 43;

const DPPWMDeviceBlock = props => {
    const {
        title,
        subtitle,
        contentBasicText,
        listItemsWithImages,
        backgroundBlock,
    } = props;

    return (
        <BackgroundBlock id={DEVICE_BLOCK} {...backgroundBlock}>
            <div className={classNames(styles.root, styles.dppDeviceBlock)}>
                {title && (
                    <div className={styles.title}>
                        {title}
                        {subtitle && (
                            <div className={styles.subtitle}>{subtitle}</div>
                        )}
                    </div>
                )}

                <div className={styles.content}>{contentBasicText}</div>
                <div className={styles.listItems}>
                    {listItemsWithImages &&
                        listItemsWithImages.map(item => {
                            const iconURL = getCloudinaryImageUrl(item.image);
                            const imageWidth = item.imageWidth || 100;
                            return (
                                <div key={item.id} className={styles.listItem}>
                                    {iconURL && (
                                        <img
                                            style={{
                                                width: `${
                                                    DEFAULT_LIST_ITEM_ICON_SIZE *
                                                    (imageWidth / 100)
                                                }px`,
                                            }}
                                            src={iconURL}
                                            alt={item.image.name}
                                        />
                                    )}

                                    <span className={styles.listItemContent}>
                                        {item.content}
                                    </span>
                                </div>
                            );
                        })}
                </div>
            </div>
        </BackgroundBlock>
    );
};

export default DPPWMDeviceBlock;
