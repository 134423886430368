// extracted by mini-css-extract-plugin
export var carouselCard = "CarouselBlock-module--carousel-card--v6fwm";
export var carouselImage = "CarouselBlock-module--carousel-image--WZlrZ";
export var container = "CarouselBlock-module--container--uB1Ux";
export var content = "CarouselBlock-module--content--Hn83H";
export var flexContainer = "CarouselBlock-module--flex-container--d-v28";
export var imageContainer = "CarouselBlock-module--image-container--gDTuG";
export var leftContainer = "CarouselBlock-module--left-container--nw8Q6";
export var mystrengthStyle = "CarouselBlock-module--mystrength-style--KGSog";
export var rightContainer = "CarouselBlock-module--right-container--r67TT";
export var root = "CarouselBlock-module--root--1ZxDu";
export var slickActive = "CarouselBlock-module--slick-active--g3Qmi";
export var slider = "CarouselBlock-module--slider--Q59jp";
export var subtitle = "CarouselBlock-module--subtitle--PuRqc";
export var title = "CarouselBlock-module--title--nZ2dn";
export var verticalDots = "CarouselBlock-module--vertical-dots--7KLls";