// extracted by mini-css-extract-plugin
export var bhFooter = "Footer-module--bh-footer--PQ5MP";
export var closeButton = "Footer-module--close-button--VE9vD";
export var container = "Footer-module--container--1cVSm";
export var ctaButtonShowing = "Footer-module--cta-button-showing--KIeHs";
export var description = "Footer-module--description--qRA8n";
export var disclaimer = "Footer-module--disclaimer--ADGrd";
export var dppBottomContainer = "Footer-module--dpp-bottom-container--1c-hr";
export var dppFooter = "Footer-module--dpp-footer--GvyCv";
export var dppImage = "Footer-module--dpp-image--789an";
export var flexContainer = "Footer-module--flex-container--rt4sB";
export var flexLinkContainer = "Footer-module--flex-link-container--5qWw8";
export var footer = "Footer-module--footer--iTLBh";
export var footerColumnLink = "Footer-module--footer-column-link--z3NVE";
export var iconSpacer = "Footer-module--icon-spacer--qoA6b";
export var leftContainer = "Footer-module--left-container--7MFqM";
export var link = "Footer-module--link--UaI6A";
export var linkColumn = "Footer-module--link-column--e2pPv";
export var linkContainer = "Footer-module--link-container--15Xts";
export var livongoComStyle = "Footer-module--livongo-com-style--PbCfl";
export var memberCommunicationPolicy = "Footer-module--member-communication-policy--NVmHh";
export var memberCommunicationPolicyBody = "Footer-module--member-communication-policy-body--s20Al";
export var memberCommunicationPolicyLink = "Footer-module--member-communication-policy-link--ZEeQt";
export var modalGrayOut = "Footer-module--modal-gray-out--Mtzot";
export var open = "Footer-module--open--aUfmv";
export var rainbowBorder = "Footer-module--rainbow-border--ldqvV";
export var rightContainer = "Footer-module--right-container--ax6Ss";
export var rightSpacer = "Footer-module--right-spacer--kPQa3";
export var row = "Footer-module--row--0+ol8";
export var socialLinkContainer = "Footer-module--social-link-container--cg4rY";
export var socialMediaRow = "Footer-module--social-media-row--BOZtR";
export var subtitle = "Footer-module--subtitle--Xq55F";
export var title = "Footer-module--title--rN4og";