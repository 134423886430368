// extracted by mini-css-extract-plugin
export var benefitTitle = "ProgramBenefitsBlock-module--benefit-title--qpRKQ";
export var blueCheckbox = "ProgramBenefitsBlock-module--blue-checkbox--hepIr";
export var bulletPoint = "ProgramBenefitsBlock-module--bullet-point--vtdSf";
export var bulletPointContainer = "ProgramBenefitsBlock-module--bullet-point-container--9duJI";
export var bulletPointUL = "ProgramBenefitsBlock-module--bullet-point-UL--vCz4D";
export var content = "ProgramBenefitsBlock-module--content--XoMej";
export var ctaButton = "ProgramBenefitsBlock-module--cta-button--FRRLz";
export var disclaimer = "ProgramBenefitsBlock-module--disclaimer--chHl5";
export var dot = "ProgramBenefitsBlock-module--dot--qsGLT";
export var howItWorks = "ProgramBenefitsBlock-module--how-it-works--0vC8I";
export var howItWorksBackgroundBlock = "ProgramBenefitsBlock-module--how-it-works-background-block--rcQD9";
export var image = "ProgramBenefitsBlock-module--image--y0xhP";
export var leftSide = "ProgramBenefitsBlock-module--left-side--NMt05";
export var mobileCtaButton = "ProgramBenefitsBlock-module--mobile-cta-button--GlonI";
export var mystrengthStyle = "ProgramBenefitsBlock-module--mystrength-style--RLPIs";
export var numCircle = "ProgramBenefitsBlock-module--num-circle--tUlSa";
export var numberedBullet = "ProgramBenefitsBlock-module--numbered-bullet--B13HR";
export var programBenefitsBackgroundBlock = "ProgramBenefitsBlock-module--program-benefits-background-block--LFHuG";
export var rightSide = "ProgramBenefitsBlock-module--right-side---6zAR";
export var root = "ProgramBenefitsBlock-module--root--iwm5I";
export var subBulletPoint = "ProgramBenefitsBlock-module--sub-bullet-point--IWh1q";
export var subBulletPointContainer = "ProgramBenefitsBlock-module--sub-bullet-point-container--H3wxl";
export var subBulletPointUL = "ProgramBenefitsBlock-module--sub-bullet-point-UL--1KJPI";
export var title = "ProgramBenefitsBlock-module--title--mDXg0";
export var wphfStyle = "ProgramBenefitsBlock-module--wphf-style--ENRfS";