import React, {useRef, useState, useEffect, useContext} from 'react';
import classNames from 'classnames';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import {HERO_BLOCK} from 'constants/blockNames';
import useWindowSize from 'hook/useWindowSize';
import {LanguageContext} from 'hook/LanguageContext';
import {GlobalContext} from 'hook/GlobalContext';
import {phoneNumberClicked} from 'utils/mixpanel-utils';
import {getCloudinaryObject} from 'utils/utilities';

import Header from 'components/header';
import CTAButton from 'components/ctaButton';
import StarBadge from 'components/starBadge';

import * as styles from './HeroBlock.module.scss';

const BACKGROUND_IMAGE_ORIENTATION = {
    WHOLE_SCREEN: 'WHOLE_SCREEN',
    RIGHT: 'RIGHT',
};

const WPHFHeroBlock = props => {
    const {
        title,
        content,
        ctaButton,
        backgroundImage,
        backgroundImageOrientation,
        mobileBackgroundImage,
        coBrandingLogo,
        coBrandingLogoWidth,
        extraTall = false,
        contactInformation,
        headerCustomization,
        starbadgeType,
    } = props;

    const {languageText} = useContext(LanguageContext);

    const {width: windowWidth, isMobile} = useWindowSize();
    const ref = useRef(null);
    const [containerHeight, setContainerHeight] = useState(null);
    const {contactNumber, contactPhoneURL} = useContext(GlobalContext);
    const onClickPhone = () => {
        phoneNumberClicked();
    };

    useEffect(() => {
        if (ref.current) {
            setContainerHeight(ref.current.offsetHeight);
        }
    }, [ref.current]);

    let backgroundImageObj =
        isMobile && mobileBackgroundImage
            ? getCloudinaryObject(mobileBackgroundImage)
            : getCloudinaryObject(backgroundImage);
    let rootStyle = {
        backgroundImage: `url(${backgroundImageObj.url})`,
    };
    let containerStyle = {};

    // For mobile, push either image to the bottom and make the container bottom padding match the size of the image height
    if (isMobile) {
        rootStyle['backgroundSize'] = 'contain';
        rootStyle['backgroundPosition'] = 'bottom';
        containerStyle['paddingBottom'] =
            (windowWidth / backgroundImageObj.width) *
            backgroundImageObj.height;

        // For an image that stretch the whole screen, use background size 'cover'
    } else if (
        backgroundImageOrientation === BACKGROUND_IMAGE_ORIENTATION.WHOLE_SCREEN
    ) {
        rootStyle['backgroundSize'] = 'cover';

        // For an image that is right aligned, use 'contain' if the width of the container is wider then image otherwise use 'cover
    } else if (
        backgroundImageOrientation === BACKGROUND_IMAGE_ORIENTATION.RIGHT
    ) {
        rootStyle['backgroundSize'] =
            windowWidth / containerHeight >
            backgroundImageObj.width / backgroundImageObj.height
                ? 'contain'
                : 'cover';
    }

    const cobrandingLogoObj = getCloudinaryObject(coBrandingLogo);

    return (
        <>
            <div
                id={HERO_BLOCK}
                ref={ref}
                className={styles.wphfHeroContainer}
                style={rootStyle}
            >
                <Header
                    cobrandingLogoUrl={cobrandingLogoObj?.url}
                    cobrandingLogoAltText={cobrandingLogoObj?.name}
                    cobrandingLogoWidth={coBrandingLogoWidth}
                    customization={headerCustomization}
                    isWPHF={true}
                />
                <div className={styles.contentBlock}>
                    <div className={styles.contentItem}>
                        <h1 className={styles.title}>
                            {renderRichText(title)}
                        </h1>

                        <div className={styles.content}>
                            {content && <div>{renderRichText(content)}</div>}
                        </div>

                        <div className={styles.contact}>
                            {ctaButton && (
                                <CTAButton
                                    id="ctaHero"
                                    isMobile={isMobile}
                                    {...ctaButton}
                                />
                            )}

                            <div>
                                <span>{languageText['or-call']}</span>
                                <button
                                    className={styles.numberButton}
                                    onClick={onClickPhone}
                                >
                                    <a href={contactPhoneURL}>
                                        <strong>{contactNumber}</strong>
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={classNames(styles.wphfBadgeItem)}
                    alt="hero_starbadge"
                >
                    <StarBadge {...starbadgeType} />
                </div>
            </div>
        </>
    );
};

export default WPHFHeroBlock;
