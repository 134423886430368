import React from 'react';
import classNames from 'classnames';

import {CAROUSEL_BLOCK} from 'constants/blockNames';
import {getCloudinaryImageUrl} from 'utils/utilities';

import * as styles from './CarouselBlock.module.scss';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CarouselBlock = props => {
    const {carouselCards} = props;

    return (
        <div id={CAROUSEL_BLOCK} className={classNames(styles.root)}>
            <div className={styles.container}>
                {carouselCards.map((carouselCard, i) => {
                    const imageURL = getCloudinaryImageUrl(
                        carouselCard.carouselImage
                    );

                    if (
                        !carouselCard.title &&
                        !carouselCard.subtitle &&
                        carouselCard.content &&
                        !imageURL
                    ) {
                        return null;
                    }

                    return (
                        <div key={carouselCard.id}>
                            <div className={styles.flexContainer}>
                                {imageURL && (
                                    <img
                                        className={styles.imageContainer}
                                        src={imageURL}
                                        alt={
                                            carouselCard.carouselImage.name
                                        }
                                    ></img>
                                )}

                                <div className={styles.carouselCard}>
                                    {carouselCard.subtitle && (
                                        <div className={styles.subtitle}>
                                            {carouselCard.subtitle}
                                        </div>
                                    )}
                                    {carouselCard.title && (
                                        <div className={styles.title}>
                                            {carouselCard.title}
                                        </div>
                                    )}
                                    {carouselCard.content && (
                                        <div className={styles.content}>
                                            {carouselCard.content.content}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default CarouselBlock;