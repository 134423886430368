import React from 'react';
import PropTypes from 'prop-types';

import SignInButton from 'components/signInButton';
import * as styles from './Header.module.scss';

const LIVONGO_LOGO = 'https://assets.livongo.com/image/upload/v1641508544/landing-pages/header-block/livongoTH_logo.png';
const DEFAULT_COBRANDING_LOGO_WIDTH = 100;

const STEPHeader = props => {
    const {
        cobrandingLogoUrl,
        cobrandingLogoAltText,
        cobrandingLogoWidth = 100,
        secondCobrandingLogoUrl,
        secondCobrandingAltText,
        signInButton,
    } = props;

    return (
        <header className={styles.stepContainer}>
            {cobrandingLogoUrl && (
                <img
                    className={styles.coBrandingLogo}
                    style={{
                        width: `${
                            (cobrandingLogoWidth * 100) /
                            DEFAULT_COBRANDING_LOGO_WIDTH
                        }px`,
                    }}
                    src={cobrandingLogoUrl}
                    alt={cobrandingLogoAltText}
                />
            )}

            <img
                className={styles.clientLogo}
                src={secondCobrandingLogoUrl}
                alt={secondCobrandingAltText}
            ></img>

            <img
                className={styles.livongoLogo}
                src={LIVONGO_LOGO}
                alt="Livongo Logo"
            ></img>

            <div className={styles.stepBtnItem}>
                <SignInButton {...signInButton} />
            </div>
        </header>
    );
};

STEPHeader.propTypes = {
    cobrandingLogoUrl: PropTypes.string,
    secondCobrandingLogoUrl: PropTypes.string,
    cobrandingLogoAltText: PropTypes.string,
    secondCobrandingAltText: PropTypes.string,
};

export default STEPHeader;
