import React from 'react';
import classNames from 'classnames';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import {PROGRAM_BENEFITS_BLOCK} from 'constants/blockNames';

import BackgroundBlock from 'components/backgroundBlock';
import CTAButton from 'components/ctaButton';

import * as styles from './ProgramBenefitsBlock.module.scss';

const ProgramBenefitsWPHF = props => {
    const {title, content, backgroundBlock, benefits, ctaButton} = props;

    return (
        <BackgroundBlock
            id={PROGRAM_BENEFITS_BLOCK}
            containerClassName={styles.programBenefitsBackgroundBlock}
            {...backgroundBlock}
        >
            <div className={classNames(styles.root)}>
                <div className={classNames(styles.wphfStyle)}>
                    {title && <div className={styles.title}>{title}</div>}
                    {content && (
                        <div className={classNames(styles.content)}>
                            {renderRichText(content)}
                        </div>
                    )}
                    {benefits?.map((benefit, i) => (
                        <div key={i} className={styles.benefitTitle}>
                            <div className={styles.numCircle}>{i + 1}</div>
                            <div>{benefit.programName}</div>
                        </div>
                    ))}
                    {ctaButton && (
                        <CTAButton
                            id="ctaWhatYouGet"
                            className={styles.ctaButton}
                            {...ctaButton}
                        />
                    )}
                </div>
            </div>
        </BackgroundBlock>
    );
};

export default ProgramBenefitsWPHF;
