import React from 'react';
import classNames from 'classnames';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import {PROGRAM_BENEFITS_BLOCK} from 'constants/blockNames';
import {getCloudinaryObject} from 'utils/utilities';

import BackgroundBlock from 'components/backgroundBlock';
import CTAButton from 'components/ctaButton';

import * as styles from './ProgramBenefitsBlock.module.scss';

const ProgramBenefitsMystrength = props => {
    const {title, content, image, benefits, backgroundBlock, ctaButton} = props;

    const imageObject = getCloudinaryObject(image);
    return (
        <BackgroundBlock
            id={PROGRAM_BENEFITS_BLOCK}
            containerClassName={styles.programBenefitsBackgroundBlock}
            {...backgroundBlock}
        >
            <div className={classNames(styles.root)}>
                <div className={classNames(styles.mystrengthStyle)}>
                    {title && <div className={styles.title}>{title}</div>}
                    {content && (
                        <div className={classNames(styles.content)}>
                            {renderRichText(content)}
                        </div>
                    )}
                    {image && (
                        <img
                            src={imageObject.url}
                            alt={image.name}
                            className={styles.image}
                        />
                    )}
                    {benefits?.map((benefit, i) => (
                        <div key={i} className={styles.bulletPointContainer}>
                            <div className={styles.benefitTitle}>
                                {benefit.programName}
                            </div>
                            <ul className={styles.bulletPointUL}>
                                <li className={styles.subBulletPointContainer}>
                                    <ul className={styles.subBulletPointUL}>
                                        {benefit.benefits &&
                                            benefit.benefits.map(
                                                (subBulletPoint, j) => (
                                                    <div
                                                        key={j}
                                                        className={
                                                            styles.subBulletPoint
                                                        }
                                                    >
                                                        <span
                                                            className={
                                                                styles.dot
                                                            }
                                                        >
                                                            •
                                                        </span>
                                                        {subBulletPoint}
                                                    </div>
                                                )
                                            )}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    ))}
                    {ctaButton && (
                        <CTAButton
                            id="ctaWhatYouGet"
                            className={styles.ctaButton}
                            {...ctaButton}
                        />
                    )}
                </div>
            </div>
        </BackgroundBlock>
    );
};

export default ProgramBenefitsMystrength;
