import React, {useState, useContext} from 'react';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import {LanguageContext} from 'hook/LanguageContext';
import {footerUrls} from 'constants/shortLinks';
import MemberPolicyModal from './MemberPolicyModal';

import * as styles from './Footer.module.scss';

const LIVONGO_LOGO_WHITE =
    'https://assets.livongo.com/image/upload/v1611338406/landing-pages/footer-block/livongo_logo_white.png';

const DPPWMFooter = props => {
    const {plNumber, disclaimer, memberCommunicationPolicy} = props;
    const {language, languageText} = useContext(LanguageContext);
    const [showPolicy, setShowPolicy] = useState(false);

    const openClosePolicy = () => {
        setShowPolicy(!showPolicy);
    };

    return (
        <footer role="contentinfo" className={styles.dppFooter}>
            <img
                className={styles.dppImage}
                src={LIVONGO_LOGO_WHITE}
                alt="Livongo Log"
            />
            <div className={styles.container}>
                <div className={styles.description}>
                    {renderRichText(disclaimer)}
                </div>
            </div>

            <div className={styles.container}>
                <div className={styles.flexContainer}>
                    <div>
                        {footerUrls.map((obj, i) => {
                            return (
                                <a
                                    key={i}
                                    className={styles.link}
                                    href={obj[language]}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {languageText[obj.name]}
                                </a>
                            );
                        })}
                        <a
                            className={styles.link}
                            href="https://livongo.eightfold.ai/careers"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <span>{languageText['careers']}</span>
                        </a>
                    </div>
                    <div className={styles.rightContainer}>
                        <div className={styles.dppBottomContainer}>
                            <div>
                                {new Date().getFullYear() +
                                    ' ' +
                                    languageText[
                                        'year-at-livongo-all-rights-reserved'
                                    ]}
                            </div>
                            <div>{plNumber}</div>
                        </div>
                    </div>
                </div>

                <MemberPolicyModal
                    openClosePolicy={openClosePolicy}
                    showPolicy={showPolicy}
                    memberCommunicationPolicy={memberCommunicationPolicy}
                />
            </div>
        </footer>
    );
};

export default DPPWMFooter;
