import React, {useContext} from 'react';
import classNames from 'classnames';

import {LanguageContext} from 'hook/LanguageContext';

import * as styles from './StarBadge.module.scss';

const BADGE_COLORS = {
    BROWN: 'BROWN',
    GREEN: 'GREEN',
};

const BADGE_TYPE = {
    MONTHLY: 'Monthly',
    LITTLE: 'Little Cost',
    FREE: 'Free',
  }

const StarBadge = ({badgeType = BADGE_TYPE.FREE , backgroundColor = BADGE_COLORS.BROWN}) => {

    const {languageText} = useContext(LanguageContext);

    const starClasses = classNames(styles.starContainer, {
        [styles.green]: backgroundColor === BADGE_COLORS.GREEN,
        [styles.brown]: backgroundColor === BADGE_COLORS.BROWN,
    });

    const priceClasses = classNames(styles.price, {
        [styles.green]: backgroundColor === BADGE_COLORS.GREEN,
        [styles.brown]: backgroundColor === BADGE_COLORS.BROWN,
    });

    return (
        <div className={starClasses}>
            <div className={priceClasses}>
                {badgeType === BADGE_TYPE.MONTHLY && 
                    (<div className={styles.monthly}>
                        <span className={styles.number}>{languageText['zero-dollars']}</span>
                        <span className={styles.label}>{languageText['per-month']}</span>
                    </div>)}

                {badgeType === BADGE_TYPE.LITTLE && (
                <div className={styles.littleCost}>
                    <span className={styles.label}>{languageText['little-cost']}</span>
                </div>)}

                {badgeType === BADGE_TYPE.FREE && 
                    (<div className={styles.free}>
                        <span className={styles.number}>{languageText['free']}</span>
                        <span className={styles.label}>{languageText['to-you']}</span>
                    </div>)}
            </div>
        </div>
    )
}

export default StarBadge;
