import React from 'react';
import classNames from 'classnames';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import {TESTIMONIALS_BLOCK} from 'constants/blockNames';
import {getCloudinaryImageUrl, removeNullFromProps} from 'utils/utilities';

import BackgroundBlock from 'components/backgroundBlock';

import * as styles from './TestimonialsBlock.module.scss';

const WPHFTestimonialsBlock = props => {
    const {
        subtitle,
        title,
        content,
        testimonialCards,
        testimonialsDisclaimer,
        backgroundBlock,
    } = removeNullFromProps(props);

    return (
        <BackgroundBlock id={TESTIMONIALS_BLOCK} {...backgroundBlock}>
            <div className={classNames(styles.root, styles.wphfContainer)}>
                {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
                {title && <div className={styles.title}>{title}</div>}
                {content && (
                    <div className={styles.content}>
                        {renderRichText(content)}
                    </div>
                )}

                <div className={classNames(styles.testimonialCards)}>
                    {testimonialCards?.map((testimonialCard, i) => {
                        const userImage = getCloudinaryImageUrl(
                            testimonialCard.userImage
                        );
                        const last3 =
                            testimonialCards.length % 2 === 1 &&
                            i >= testimonialCards.length - 3;
                        const last3Small = last3 && i % 2 === 0;
                        const last3Large = last3 && i % 2 === 1;
                        const offset =
                            !last3 && (Math.floor(i / 2) + i) % 2 === 1;

                        return (
                            <div
                                key={testimonialCard.id}
                                className={classNames(styles.cardItem, {
                                    [styles.offset]: offset,
                                    [styles.last3Small]: last3Small,
                                    [styles.last3Large]: last3Large,
                                })}
                            >
                                {userImage && (
                                    <img
                                        className={styles.memberImage}
                                        src={userImage}
                                        alt={testimonialCard.userImage.name}
                                    />
                                )}
                                <div className={styles.testimonial}>
                                    {renderRichText(
                                        testimonialCard.memberTestimonial
                                    )}
                                </div>

                                <div className={styles.memberDetails}>
                                    {testimonialCard.memberName && (
                                        <div className={styles.memberName}>
                                            {testimonialCard.memberName}
                                        </div>
                                    )}

                                    {testimonialCard.memberDescription && (
                                        <div
                                            className={styles.memberDescription}
                                        >
                                            {testimonialCard.memberDescription}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>

                {testimonialsDisclaimer?.internal?.content && (
                    <div className={styles.testimonialsDisclaimer}>
                        {testimonialsDisclaimer.internal.content}
                    </div>
                )}
            </div>
        </BackgroundBlock>
    );
};

export default WPHFTestimonialsBlock;
