import React from 'react';
import classNames from 'classnames';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import {TESTIMONIALS_BLOCK} from 'constants/blockNames';
import {getCloudinaryImageUrl, removeNullFromProps} from 'utils/utilities';

import BackgroundBlock from 'components/backgroundBlock';

import * as styles from './TestimonialsBlock.module.scss';

const LivongoComTestimonialsBlock = props => {
    const {
        subtitle,
        title,
        content,
        testimonialCards,
        testimonialsDisclaimer,
        backgroundBlock,
    } = removeNullFromProps(props);

    return (
        <BackgroundBlock id={TESTIMONIALS_BLOCK} {...backgroundBlock}>
            <div className={classNames(styles.root, styles.livongoComStyle)}>
                {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
                {title && <div className={styles.title}>{title}</div>}
                {content && (
                    <div className={styles.content}>
                        {renderRichText(content)}
                    </div>
                )}

                <div className={classNames(styles.testimonialCards)}>
                    {testimonialCards &&
                        testimonialCards.map((testimonialCard, i) => {
                            const userImage = getCloudinaryImageUrl(
                                testimonialCard.userImage
                            );
                            return (
                                <div
                                    key={testimonialCard.id}
                                    className={classNames(styles.cardItem)}
                                >
                                    {userImage && (
                                        <img
                                            className={styles.memberImage}
                                            src={userImage}
                                            alt={testimonialCard.userImage.name}
                                        />
                                    )}

                                    {testimonialCard.memberDescription && (
                                        <div
                                            className={styles.memberDescription}
                                        >
                                            {testimonialCard.memberDescription}
                                        </div>
                                    )}
                                    <div className={styles.testimonial}>
                                        {renderRichText(
                                            testimonialCard.memberTestimonial
                                        )}
                                    </div>
                                    {testimonialCard.memberName && (
                                        <div className={styles.memberName}>
                                            {testimonialCard.memberName}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                </div>

                {testimonialsDisclaimer?.internal?.content && (
                    <div className={styles.testimonialsDisclaimer}>
                        {testimonialsDisclaimer.internal.content}
                    </div>
                )}
            </div>
        </BackgroundBlock>
    );
};

export default LivongoComTestimonialsBlock;
