let footerUrls = [
    {
        name: 'web-and-mobile-privacy-policy',
        'en-US': `https://teladochealth.com/privacy-policy/`,
        'es-US': `https://teladochealth.com/es/privacy-policy/`,
    },
    {
        name: 'legal-disclaimer',
        'en-US': `https://teladochealth.com/legal-disclaimer/`,
        'es-US': `https://teladochealth.com/es/legal-disclaimer/`,
    },
    {
        name: 'notice-of-non-discrimination-and-language-assistance',
        'en-US': `https://teladochealth.com/notice-of-non-discrimination/`,
        'es-US': `https://teladochealth.com/es/notice-of-non-discrimination/`,
    },
    {
        name: 'accessibility-statement',
        'en-US': `https://teladochealth.com/accessibility-statement/`,
        'es-US': `https://teladochealth.com/accessibility-statement/`,
    },
];

export {footerUrls};
