import React, {useState, useContext} from 'react';
import classNames from 'classnames';
import htmlStringReactParser, {domToReact} from 'html-react-parser';
import {graphql} from 'gatsby';

import {convertEnvironmentLivongoLink} from 'utils/utilities';
import {ChatContext} from 'hook/ChatContext';
import {FAQ_BLOCK} from 'constants/blockNames';
import {GlobalContext} from 'hook/GlobalContext';
import useWindowSize from 'hook/useWindowSize';

import BackgroundBlock from 'components/backgroundBlock';
import DPPWMFAQBlock from './DPPWMFAQBlock';
import MedicareAdvantageFAQBlock from './MedicareAdvantageFAQBlock';

import * as styles from './FAQBlock.module.scss';

const CUSTOMIZATIONS = {
    DPPWM_STYLE: 'DPPWM_STYLE',
    LIVONGO_COM_STYLE: 'LIVONGO_COM_STYLE',
    MYSTRENGTH_STYLE: 'MYSTRENGTH_STYLE',
    MEDICARE_ADVANTAGE_STYLE: 'MEDICARE_ADVANTAGE_STYLE',
};

const FAQBlock = props => {
    const {
        subtitle,
        title,
        columns = 2,
        questions,
        backgroundBlock,
        customization,
    } = props;

    const {onClickChat} = useContext(ChatContext);
    const {contactNumber, contactPhoneURL} = useContext(GlobalContext);

    const loginURL = convertEnvironmentLivongoLink(process.env.LOGIN_URL);
    const signUpURL = convertEnvironmentLivongoLink(process.env.SIGN_UP_URL);

    const options = {
        replace: ({attribs, children}) => {
            if (!attribs) {
                return;
            }

            switch (attribs.id) {
                case 'chat':
                    const targetChildren = children.filter(child => {
                        return child.data && child.data.indexOf('chat with us');
                    });
                    const remainingChildren = children.filter(child => {
                        return (
                            !child.data ||
                            !(child.data && child.data.indexOf('chat with us'))
                        );
                    });

                    return (
                        <>
                            <span
                                className={styles.openChat}
                                onClick={onClickChat}
                            >
                                {domToReact(targetChildren, options)}
                            </span>
                            {domToReact(remainingChildren, options)}
                        </>
                    );

                case 'phoneNumber':
                    return <a href={contactPhoneURL}>{contactNumber}</a>;

                case 'myLivongo':
                    return <a href={loginURL}>{'my.livongo.com'}</a>;

                case 'signup':
                    return <a href={signUpURL}>{'signup.com'}</a>;

                default:
                    break;
            }
        },
    };

    if (customization === CUSTOMIZATIONS.DPPWM_STYLE) {
        return <DPPWMFAQBlock {...props} options={options} />;
    } else if (customization === CUSTOMIZATIONS.MEDICARE_ADVANTAGE_STYLE) {
        return <MedicareAdvantageFAQBlock {...props} options={options} />;
    }

    const {isMobile} = useWindowSize();
    const [showAnswers, setShowAnswers] = useState({});

    const handleQuestionClick = id => {
        setShowAnswers({...showAnswers, [id]: !showAnswers[id]});
    };

    const renderQuestion = question => (
        <div key={question.id} className={classNames(styles.questionSection)}>
            <div
                className={classNames(styles.question)}
                onClick={() => handleQuestionClick(question.id)}
            >
                <i
                    className={classNames(
                        styles.glypicon,
                        showAnswers[question.id] ? styles.minus : styles.plus
                    )}
                ></i>
                {question.question.question}
            </div>
            <div
                className={classNames(styles.answer, {
                    [styles.show]: showAnswers[question.id],
                })}
            >
                {htmlStringReactParser(
                    question.answer.childMarkdownRemark.html,
                    options
                )}
            </div>
        </div>
    );
    const renderBySize = isMobile => {
        if (isMobile || columns === 1) {
            return (
                <div
                    className={classNames(
                        styles.leftRightContainer,
                        styles.singleColumn
                    )}
                >
                    <div className={styles.questionsSection}>
                        {questions &&
                            questions.map((question, i) =>
                                renderQuestion(question)
                            )}
                    </div>
                </div>
            );
        } else {
            return (
                <div className={classNames(styles.leftRightContainer)}>
                    <div className={styles.questionsSection}>
                        {questions &&
                            questions.map((question, i) =>
                                i % 2 === 0 ? renderQuestion(question) : null
                            )}
                    </div>
                    <div className={styles.questionsSection}>
                        {questions &&
                            questions.map((question, i) =>
                                i % 2 === 1 ? renderQuestion(question) : null
                            )}
                    </div>
                </div>
            );
        }
    };

    return (
        <BackgroundBlock id={FAQ_BLOCK} {...backgroundBlock}>
            <div
                className={classNames(styles.root, {
                    [styles.livongoComStyle]:
                        customization === CUSTOMIZATIONS.LIVONGO_COM_STYLE,
                    [styles.mystrengthStyle]:
                        customization === CUSTOMIZATIONS.MYSTRENGTH_STYLE,
                })}
            >
                {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
                {title && <div className={styles.title}>{title}</div>}
                {renderBySize(isMobile)}
            </div>
        </BackgroundBlock>
    );
};

export default FAQBlock;

export const query = graphql`
    fragment FAQBlock on ContentfulFaqBlock {
        id
        name
        subtitle
        title
        columns
        questions {
            id
            question {
                question
            }
            answer {
                answer
                childMarkdownRemark {
                    html
                }
            }
        }
        backgroundBlock {
            ...BackgroundBlock
        }
        customization
    }
`;
