import React from 'react';
import classNames from 'classnames';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import {CTA_BLOCK} from 'constants/blockNames';

import BackgroundBlock from 'components/backgroundBlock';
import CTAButton from 'components/ctaButton';

import * as styles from './CTABlock.module.scss';

const TEXT_ALIGN = {
    LEFT: 'LEFT',
    CENTER: 'CENTER',
    RIGHT: 'RIGHT',
};

const DPPWMCTABlock = props => {
    const {
        subtitle,
        title,
        content,
        ctaButton,
        contactInformation,
        textAlign, // default is CENTER
        backgroundBlock,
        className,
        backgroundBlockContainerClassName,
    } = props;

    return (
        <BackgroundBlock id={CTA_BLOCK} {...backgroundBlock}>
            <div
                className={classNames(styles.root, styles.dppRoot, className, {
                    [styles.left]: textAlign === TEXT_ALIGN.LEFT,
                    [styles.right]: textAlign === TEXT_ALIGN.RIGHT,
                })}
            >
                {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
                {title && <div className={styles.title}>{title}</div>}
                {content && (
                    <div className={styles.content}>
                        {renderRichText(content)}
                    </div>
                )}
                {ctaButton && (
                    <CTAButton
                        id="ctaButton"
                        isBold
                        {...ctaButton}
                        isDPPWM={true}
                    ></CTAButton>
                )}
                {contactInformation && (
                    <div className={styles.contactInformation}>
                        {renderRichText(contactInformation)}
                    </div>
                )}
            </div>
        </BackgroundBlock>
    );
};

export default DPPWMCTABlock;
