import React, {useState} from 'react';
import classNames from 'classnames';
import htmlStringReactParser from 'html-react-parser';

import {FAQ_BLOCK} from 'constants/blockNames';
import useWindowSize from 'hook/useWindowSize';

import BackgroundBlock from 'components/backgroundBlock';

import * as styles from './FAQBlock.module.scss';

const DPPWMFAQBlock = props => {
    const {subtitle, columns = 2, questions, options, backgroundBlock} = props;

    const {isMobile} = useWindowSize();
    const [showAnswers, setShowAnswers] = useState({});

    const handleQuestionClick = id => {
        setShowAnswers({...showAnswers, [id]: !showAnswers[id]});
    };

    const renderQuestion = question => (
        <div key={question.id} className={classNames(styles.questionSection)}>
            <div
                className={classNames(styles.question)}
                onClick={() => handleQuestionClick(question.id)}
            >
                <i
                    className={classNames(
                        styles.glypicon,
                        showAnswers[question.id] ? styles.minus : styles.plus
                    )}
                ></i>
                <div>{question.question.question}</div>
            </div>
            <div
                className={classNames(styles.answer, {
                    [styles.show]: showAnswers[question.id],
                })}
            >
                {htmlStringReactParser(
                    question.answer.childMarkdownRemark.html,
                    options
                )}
            </div>
        </div>
    );

    const renderBySize = isMobile => {
        if (isMobile || columns === 1) {
            return (
                <div
                    className={classNames(
                        styles.leftRightContainer,
                        styles.singleColumn
                    )}
                >
                    <div className={styles.questionsSection}>
                        {questions &&
                            questions.map((question, i) =>
                                renderQuestion(question)
                            )}
                    </div>
                </div>
            );
        } else {
            return (
                <div className={classNames(styles.leftRightContainer)}>
                    <div className={styles.questionsSection}>
                        {questions &&
                            questions.map((question, i) =>
                                i % 2 === 0 ? renderQuestion(question) : null
                            )}
                    </div>
                    <div className={styles.questionsSection}>
                        {questions &&
                            questions.map((question, i) =>
                                i % 2 === 1 ? renderQuestion(question) : null
                            )}
                    </div>
                </div>
            );
        }
    };

    return (
        <BackgroundBlock id={FAQ_BLOCK} {...backgroundBlock}>
            <div className={classNames(styles.root, styles.dppContainer)}>
                {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
                {renderBySize(isMobile)}
            </div>
        </BackgroundBlock>
    );
};

export default DPPWMFAQBlock;
