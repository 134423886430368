import React, {useContext} from 'react';
import classNames from 'classnames';
import {graphql} from 'gatsby';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import {PROGRAM_BENEFITS_BLOCK} from 'constants/blockNames';
import {getCloudinaryObject} from 'utils/utilities';

import BackgroundBlock from 'components/backgroundBlock';
import CTAButton from 'components/ctaButton';

import blueCheckbox from './images/blue-checkbox.svg';
import * as styles from './ProgramBenefitsBlock.module.scss';

const ProgramBenefitsHowItWorksBlock = props => {
    const {
        title,
        content,
        image,
        benefits,
        ctaButton,
        disclaimer,
        backgroundBlock,
    } = props;

    const imageObject = getCloudinaryObject(image);

    return (
        <BackgroundBlock
            id={PROGRAM_BENEFITS_BLOCK}
            containerClassName={styles.howItWorksBackgroundBlock}
            {...backgroundBlock}
        >
            <div className={classNames(styles.root, styles.howItWorks)}>
                <div className={styles.leftSide}>
                    {image && (
                        <img
                            src={imageObject.url}
                            alt={image.name}
                            className={styles.image}
                        />
                    )}
                    {title && <div className={styles.title}>{title}</div>}
                    {content && (
                        <div className={styles.content}>
                            {renderRichText(content)}
                        </div>
                    )}
                    {ctaButton && (
                        <CTAButton
                            id="ctaWhatYouGet"
                            className={styles.ctaButton}
                            {...ctaButton}
                        />
                    )}
                </div>
                <div className={styles.rightSide}>
                    {benefits.map((benefit, i) => (
                        <div key={i} className={styles.bulletPointContainer}>
                            <ul className={styles.bulletPointUL}>
                                <li className={styles.bulletPoint}>
                                    <div className={styles.numberedBullet}>
                                        <span>{i + 1}</span>
                                    </div>
                                    {benefit.programName}
                                </li>
                                <li className={styles.subBulletPointContainer}>
                                    <ul className={styles.subBulletPointUL}>
                                        {benefit.benefits &&
                                            benefit.benefits.map(
                                                (subBulletPoint, j) => (
                                                    <div
                                                        key={j}
                                                        className={
                                                            styles.subBulletPoint
                                                        }
                                                    >
                                                        <span
                                                            className={
                                                                styles.dot
                                                            }
                                                        >
                                                            •
                                                        </span>
                                                        {subBulletPoint}
                                                    </div>
                                                )
                                            )}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    ))}

                    {disclaimer && (
                        <div className={styles.disclaimer}>
                            {renderRichText(disclaimer)}
                        </div>
                    )}
                    {ctaButton && (
                        <CTAButton
                            id="ctaWhatYouGet"
                            className={classNames(
                                styles.ctaButton,
                                styles.mobileCtaButton
                            )}
                            {...ctaButton}
                        />
                    )}
                </div>
            </div>
        </BackgroundBlock>
    );
};

export default ProgramBenefitsHowItWorksBlock;
