import React from 'react';
import classNames from 'classnames';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import {FOCUS_BLOCK} from 'constants/blockNames';
import useWindowSize from 'hook/useWindowSize';

import BackgroundBlock from 'components/backgroundBlock';
import CTAButton from 'components/ctaButton';

import * as styles from './FocusBlock.module.scss';

const MyStrengthFocusBlock = props => {
    const {
        title,
        subtitle,
        content,
        points,
        ctaButton,
        backgroundBlock,
    } = props;
    const windowSize = useWindowSize();
    const isMobile = windowSize.isMobile;

    return (
        <BackgroundBlock id={FOCUS_BLOCK} {...backgroundBlock}>
            <div className={styles.mystrengthFocusBlockContainer}>
                {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
                {title && <div className={styles.titleItem}>{title}</div>}
                {content && (
                    <div className={styles.contentItem}>
                        {renderRichText(content)}
                    </div>
                )}
                <ul className={styles.pointsItem}>
                    {points &&
                        points.map((point, i) => (
                            <li key={i} className={styles.point}>
                                {point}
                            </li>
                        ))}
                </ul>

                <div className={styles.ctaBtn}>
                    {ctaButton && (
                        <CTAButton
                            id="ctaHero"
                            isMobile={isMobile}
                            {...ctaButton}
                        />
                    )}
                </div>
            </div>
        </BackgroundBlock>
    );
};

export default MyStrengthFocusBlock;
