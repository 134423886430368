import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {convertEnvironmentLivongoLink} from 'utils/utilities';
import {signInClicked} from 'utils/mixpanel-utils';

import * as styles from './SignInButton.module.scss';

const BUTTON_COLORS = {
    BLUE: 'BLUE',
    GREEN: 'GREEN',
};

const SignInButton = props => {
    const {backgroundColor, buttonText, url} = props;

    const classes = classNames(styles.signinContainer, {
        [styles.green]: backgroundColor === BUTTON_COLORS.GREEN,
        [styles.blue]: backgroundColor === BUTTON_COLORS.BLUE,
    });

    const [loginURL, setLoginURL] = useState(url);

    useEffect(() => {
        let newURL = convertEnvironmentLivongoLink(url);
        newURL += window.location.search;
        setLoginURL(newURL);
    }, []);

    const onClickSignIn = () => {
        signInClicked();

        window.location = loginURL;
    };

    return (
        <button className={classes} onClick={onClickSignIn}>
            {buttonText}
        </button>
    );
};

SignInButton.propTypes = {
    backgroundColor: PropTypes.string,
    buttonText: PropTypes.string,
    url: PropTypes.string,
};

export default SignInButton;
