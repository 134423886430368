import React, {useState, useEffect, useContext} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {GlobalContext} from 'hook/GlobalContext';
import {LanguageContext} from 'hook/LanguageContext';
import {convertEnvironmentLivongoLink} from 'utils/utilities';
import {signInClicked} from 'utils/mixpanel-utils';

import CTAButton from 'components/ctaButton';

import purpleTelephone from './images/purple-phone.png';
import * as styles from './Header.module.scss';

const LIVONGO_LOGO =
    'https://assets.livongo.com/image/upload/v1641508544/landing-pages/header-block/livongoTH_logo.png';

const SCROLL_DISTANCE = 50;

const LivongoComHeader = props => {
    const {
        cobrandingLogoUrl,
        cobrandingLogoAltText,
        cobrandingLogoWidth = 100,
        secondCobrandingLogoUrl,
        secondCobrandingAltText,
        customization,
        isMobile,
    } = props;

    const {contactNumber, contactPhoneURL} = useContext(GlobalContext);
    const {languageText} = useContext(LanguageContext);
    const [showFloatingHeader, setShowFloatingHeader] = useState(false);

    const loginURL = convertEnvironmentLivongoLink(process.env.LOGIN_URL);

    // Event Handlers for scroll
    useEffect(() => {
        window.addEventListener('scroll', e => handleNavigation(e));

        return () => {
            // return a cleanup function to unregister our function since its gonna run multiple times
            window.removeEventListener('scroll', e => handleNavigation(e));
        };
    }, []);

    const handleNavigation = e => {
        let currentY = e.currentTarget.scrollY;
        if (currentY > SCROLL_DISTANCE && !showFloatingHeader) {
            setShowFloatingHeader(true);
        } else if (currentY <= SCROLL_DISTANCE && showFloatingHeader) {
            setShowFloatingHeader(false);
        } else if (currentY === 0) {
            setShowFloatingHeader(false);
        }
    };

    const onClickSignIn = () => {
        signInClicked();

        window.location = loginURL;
    };

    return (
        <header className={classNames(styles.header, styles.livongoComHeader)}>
            <FloatingLivongoComHeader show={showFloatingHeader} />
            <div className={classNames(styles.logoContainer)}>
                {cobrandingLogoUrl && (
                    <div className={styles.coBrandingLogoContainer}>
                        <img
                            className={styles.coBrandingLogo}
                            style={{width: `${cobrandingLogoWidth}%`}}
                            src={cobrandingLogoUrl}
                            alt={cobrandingLogoAltText}
                        ></img>
                    </div>
                )}
                <img
                    className={styles.logo}
                    src={LIVONGO_LOGO}
                    alt="Livongo Logo"
                />
            </div>

            <div className={styles.rightSide}>
                {!isMobile && (
                    <a href={`${contactPhoneURL}`} className={styles.telephone}>
                        <img
                            src={purpleTelephone}
                            className={styles.phoneIcon}
                            width={15}
                            height={15}
                        />
                        <div className={styles.phoneNumber}>
                            {`${contactNumber}`}
                        </div>
                    </a>
                )}

                <button className={styles.signInButton} onClick={onClickSignIn}>
                    {languageText['log-in']}
                </button>

                {!isMobile && (
                    <CTAButton
                        id="ctaHero"
                        className={styles.ctaButton}
                        buttonText={languageText['join-now']}
                        backgroundColor={'PURPLE'}
                        customization={'LIVONGO_COM_STYLE'}
                    />
                )}
            </div>
        </header>
    );
};

const FloatingLivongoComHeader = ({show = false}) => {
    const {contactNumber, contactPhoneURL} = useContext(GlobalContext);
    const {languageText} = useContext(LanguageContext);

    return (
        <div
            className={classNames(styles.livongoComFloatingHeader, {
                [styles.show]: show,
            })}
        >
            <div className={styles.container}>
                <img
                    className={styles.logo}
                    src={LIVONGO_LOGO}
                    alt="Livongo Logo"
                />
                <div className={styles.rightSide}>
                    <a href={`${contactPhoneURL}`} className={styles.telephone}>
                        <div className={styles.callUs}>
                            {languageText['call-us']}
                        </div>
                        <div className={styles.phoneNumber}>
                            <img
                                src={purpleTelephone}
                                className={styles.phoneIcon}
                                width={15}
                                height={15}
                            />
                            {`${contactNumber}`}
                        </div>
                    </a>
                    <CTAButton
                        id="ctaHero"
                        className={styles.ctaButton}
                        buttonText={languageText['join-now']}
                        backgroundColor={'PURPLE'}
                        customization={'LIVONGO_COM_STYLE'}
                    />
                </div>
            </div>
        </div>
    );
};

LivongoComHeader.propTypes = {
    cobrandingLogoUrl: PropTypes.string,
    cobrandingLogoAltText: PropTypes.string,
    cobrandingLogoWidth: PropTypes.number,
    secondCobrandingLogoUrl: PropTypes.string,
};

export default LivongoComHeader;
