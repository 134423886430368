import React, {useRef, useState, useEffect, useContext} from 'react';
import classNames from 'classnames';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import {LanguageContext} from 'hook/LanguageContext';
import {HERO_BLOCK} from 'constants/blockNames';
import useWindowSize from 'hook/useWindowSize';
import {getCloudinaryImageUrl, getCloudinaryObject} from 'utils/utilities';

import Header from 'components/header';
import CTAButton from 'components/ctaButton';

import * as styles from './HeroBlock.module.scss';

const BACKGROUND_IMAGE_ORIENTATION = {
    WHOLE_SCREEN: 'WHOLE_SCREEN',
    RIGHT: 'RIGHT',
};

const LivongoComHeroBlock = props => {
    return (
        <div id={HERO_BLOCK}>
            <div className={styles.desktopView}>
                <DesktopHeroBlock {...props} />
            </div>
            <div className={styles.mobileView}>
                <MobileHeroBlock {...props} />
            </div>
        </div>
    );
};

const DesktopHeroBlock = props => {
    const {
        title,
        content,
        // noCostBadge,
        ctaButton,
        backgroundImage,
        backgroundImageOrientation = BACKGROUND_IMAGE_ORIENTATION.WHOLE_SCREEN,
        // mobileBackgroundImage,
        coBrandingLogo,
        coBrandingLogoWidth,
        // secondCobrandingLogo,
        extraTall = false,
        // contactDescription,
        // customization = CUSTOMIZATIONS.NORMAL,
        headerCustomization,
    } = props;

    const {width: windowWidth} = useWindowSize();
    const ref = useRef(null);
    const [containerHeight, setContainerHeight] = useState(null);

    useEffect(() => {
        if (ref.current) {
            setContainerHeight(ref.current.offsetHeight);
        }
    }, [ref.current]);

    let backgroundImageObj = getCloudinaryObject(backgroundImage);
    let rootStyle = {
        backgroundImage: `url(${backgroundImageObj.url})`,
    };
    let containerStyle = {};

    if (
        backgroundImageOrientation === BACKGROUND_IMAGE_ORIENTATION.WHOLE_SCREEN
    ) {
        rootStyle['backgroundSize'] = 'cover';

        // For an image that is right aligned, use 'contain' if the width of the container is wider then image otherwise use 'cover
    } else if (
        backgroundImageOrientation === BACKGROUND_IMAGE_ORIENTATION.RIGHT
    ) {
        rootStyle['backgroundSize'] =
            windowWidth / containerHeight >
            backgroundImageObj.width / backgroundImageObj.height
                ? 'contain'
                : 'cover';
    }

    const cobrandingLogoObj = getCloudinaryObject(coBrandingLogo);

    return (
        <div
            ref={ref}
            className={classNames(
                styles.root,
                styles.livongoComStyle,
                styles.heroImage
            )}
            style={rootStyle}
        >
            <div
                className={classNames(styles.container, {
                    [styles.extraTall]: extraTall,
                })}
                style={containerStyle}
            >
                <Header
                    cobrandingLogoUrl={cobrandingLogoObj?.url}
                    cobrandingLogoAltText={cobrandingLogoObj?.name}
                    cobrandingLogoWidth={coBrandingLogoWidth}
                    customization={headerCustomization}
                />

                <h1 className={styles.title}>{renderRichText(title)}</h1>
                {content && (
                    <div className={styles.content}>
                        {renderRichText(content)}
                    </div>
                )}
                {ctaButton && (
                    <CTAButton id="ctaHero" isBold={true} {...ctaButton} />
                )}
            </div>
        </div>
    );
};

const MobileHeroBlock = props => {
    const {
        title,
        content,
        // noCostBadge,
        ctaButton,
        backgroundImage,
        // backgroundImageOrientation = BACKGROUND_IMAGE_ORIENTATION.WHOLE_SCREEN,
        mobileBackgroundImage,
        coBrandingLogo,
        coBrandingLogoWidth,
        secondCobrandingLogo,
        // extraTall = false,
        // contactDescription,
        // customization = CUSTOMIZATIONS.NORMAL,
        headerCustomization,
        signInButton,
    } = props;

    const {width: windowWidth} = useWindowSize();

    let backgroundImageObj =
        getCloudinaryObject(mobileBackgroundImage) ||
        getCloudinaryObject(backgroundImage);
    let rootStyle = {
        backgroundImage: `url(${backgroundImageObj.url})`,
    };

    const cobrandingLogoObj = getCloudinaryObject(coBrandingLogo);
    const secondCobrandingLogoObj = getCloudinaryObject(secondCobrandingLogo);

    return (
        <div
            id={HERO_BLOCK}
            className={classNames(
                styles.root,
                styles.mobileHeroBlock,
                styles.livongoComStyle
            )}
        >
            <div className={classNames(styles.marginDiv)}>
                <Header
                    cobrandingLogoUrl={cobrandingLogoObj?.url}
                    cobrandingLogoAltText={cobrandingLogoObj?.name}
                    secondCobrandingLogoUrl={secondCobrandingLogoObj?.url}
                    cobrandingLogoWidth={coBrandingLogoWidth}
                    secondCobrandingAltText={secondCobrandingLogoObj?.name}
                    customization={headerCustomization}
                    signInButton={signInButton}
                    isMobile
                />
                <h1 className={styles.title}>{renderRichText(title)}</h1>
                {content && (
                    <div className={styles.content}>
                        {renderRichText(content)}
                    </div>
                )}
                {ctaButton && (
                    <div className={styles.mobileCtaContainer}>
                        <CTAButton
                            id="ctaHero"
                            className={styles.ctaButton}
                            {...ctaButton}
                        />
                    </div>
                )}
            </div>
            <div
                className={styles.mobileHeroImage}
                style={{
                    backgroundImage: `url(${backgroundImageObj.url})`,
                    height:
                        (windowWidth / backgroundImageObj.width) *
                        backgroundImageObj.height,
                }}
            ></div>

            <div className={classNames(styles.marginDiv)}></div>
        </div>
    );
};

export default LivongoComHeroBlock;
