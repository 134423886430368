import React from 'react';
import classNames from 'classnames';
import {graphql} from 'gatsby';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import {FOCUS_BLOCK} from 'constants/blockNames';
import useWindowSize from 'hook/useWindowSize';

import BackgroundBlock from 'components/backgroundBlock';
import CTAButton from 'components/ctaButton';
import MyStrengthFocusBlock from './MyStrengthFocusBlock'

import * as styles from './FocusBlock.module.scss';

const CUSTOMIZATIONS = {
    NORMAL: 'NORMAL',
    MYSTRENGTH_DIGITAL: 'MYSTRENGTH_DIGITAL',
};

const FocusBlock = props => {
    const {
        title,
        subtitle,
        content,
        points,
        customization,
        ctaButton,
        backgroundBlock,
    } = props;
    const windowSize = useWindowSize();
    const isMobile = windowSize.isMobile;
    
    switch(customization) {
        case CUSTOMIZATIONS.MYSTRENGTH_DIGITAL:
            return <MyStrengthFocusBlock {...props}/>
    }

    return (
        <BackgroundBlock id={FOCUS_BLOCK} {...backgroundBlock}>
            <div id={FOCUS_BLOCK} className={classNames(styles.root)}>
                {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
                {title && <div className={styles.title}>{title}</div>}
                <ul className={styles.pointsContainer}>
                    {points &&
                        points.map((point, i) => (
                            <li key={i} className={styles.point}>
                                {point}
                            </li>
                        ))}
                </ul>
                {content && (
                    <div className={styles.content}>
                        {renderRichText(content)}
                    </div>
                )}
                {ctaButton && (
                    <div className={styles.ctaButton}>
                        <CTAButton
                            id="ctaFocus"
                            isMobile={isMobile}
                            {...ctaButton}
                        />
                    </div>
                )}
            </div>
        </BackgroundBlock>
    );
};

export default FocusBlock;

export const query = graphql`
    fragment FocusBlock on ContentfulFocusBlock {
        name
        title
        subtitle
        points
        customization
        content {
            raw
        }
        ctaButton {
            ...CTAButton
        }
        backgroundBlock {
            ...BackgroundBlock
        }
    }
`;
