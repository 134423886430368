import React from 'react';
import classNames from 'classnames';
import {graphql} from 'gatsby';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import {TESTIMONIALS_BLOCK} from 'constants/blockNames';
import {getCloudinaryImageUrl, removeNullFromProps} from 'utils/utilities';

import BackgroundBlock from 'components/backgroundBlock';

import DPPWMTestimonialsBlock from './DPPWMTestimonialsBlock';
import MyStrengthTestimonialsBlock from './MyStrengthTestimonialsBlock';
import LivongoComTestimonialsBlock from './LivongoComTestimonialsBlock';
import WPHFTestimonialsBlock from './WPHFTestimonialsBlock';

import * as styles from './TestimonialsBlock.module.scss';

const CUSTOMIZATIONS = {
    DPPWM_STYLE: 'DPPWM_STYLE',
    OFFSET_STYLE: 'OFFSET_STYLE',
    MYSTRENGTH_STYLE: 'MYSTRENGTH_STYLE',
    LIVONGO_COM_STYLE: 'LIVONGO_COM_STYLE',
    WPHF_STYLE: 'WPHF_STYLE',
};

const TestimonialsBlock = props => {
    const {
        subtitle,
        title,
        content,
        testimonialCards,
        testimonialsDisclaimer,
        backgroundBlock,
        customization,
    } = removeNullFromProps(props);

    switch (customization) {
        case CUSTOMIZATIONS.DPPWM_STYLE:
            return <DPPWMTestimonialsBlock {...props} />;
        case CUSTOMIZATIONS.MYSTRENGTH_STYLE:
            return <MyStrengthTestimonialsBlock {...props} />;
        case CUSTOMIZATIONS.LIVONGO_COM_STYLE:
            return <LivongoComTestimonialsBlock {...props} />;
        case CUSTOMIZATIONS.WPHF_STYLE:
            return <WPHFTestimonialsBlock {...props} />;
    }

    return (
        <BackgroundBlock id={TESTIMONIALS_BLOCK} {...backgroundBlock}>
            <div className={classNames(styles.root)}>
                {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
                {title && <div className={styles.title}>{title}</div>}
                {content && (
                    <div className={styles.content}>
                        {renderRichText(content)}
                    </div>
                )}

                <div
                    className={classNames(styles.testimonialCards, {
                        [styles.largerTextStyle]:
                            customization === CUSTOMIZATIONS.OFFSET_STYLE,
                    })}
                >
                    {testimonialCards &&
                        testimonialCards.map((testimonialCard, i) => {
                            const userImage = getCloudinaryImageUrl(
                                testimonialCard.userImage
                            );

                            // For offset
                            // If last 3, do a 1 2 1 for last row
                            // otherwise do a 1 2, 2 1, 1 2, snake pattern

                            const last3 =
                                testimonialCards.length % 2 === 1 &&
                                i >= testimonialCards.length - 3;
                            const last3Small = last3 && i % 2 === 0;
                            const last3Large = last3 && i % 2 === 1;
                            const offset =
                                !last3 && (Math.floor(i / 2) + i) % 2 === 1;
                            return (
                                <div
                                    key={testimonialCard.id}
                                    className={classNames(styles.cardItem, {
                                        [styles.offset]: offset,
                                        [styles.last3Small]: last3Small,
                                        [styles.last3Large]: last3Large,
                                    })}
                                >
                                    {userImage && (
                                        <img
                                            className={styles.memberImage}
                                            src={userImage}
                                            alt={testimonialCard.userImage.name}
                                        />
                                    )}
                                    {testimonialCard.memberName && (
                                        <div className={styles.memberName}>
                                            {testimonialCard.memberName}
                                        </div>
                                    )}
                                    {testimonialCard.memberDescription && (
                                        <div
                                            className={styles.memberDescription}
                                        >
                                            {testimonialCard.memberDescription}
                                        </div>
                                    )}
                                    <div className={styles.testimonial}>
                                        {renderRichText(
                                            testimonialCard.memberTestimonial
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                </div>

                {testimonialsDisclaimer?.internal?.content && (
                    <div className={styles.testimonialsDisclaimer}>
                        {testimonialsDisclaimer.internal.content}
                    </div>
                )}
            </div>
        </BackgroundBlock>
    );
};

export default TestimonialsBlock;

export const query = graphql`
    fragment TestimonialsBlock on ContentfulTestimonialsBlock {
        id
        name
        subtitle
        title
        content {
            raw
        }
        testimonialCards {
            id
            name
            memberName
            memberDescription
            memberTestimonial {
                raw
            }
            userImage {
                ...CloudinaryImage
            }
        }
        testimonialsDisclaimer {
            internal {
                content
            }
        }
        backgroundBlock {
            ...BackgroundBlock
        }
        customization
    }
`;
