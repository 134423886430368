import React, {useRef, useState, useEffect} from 'react';
import classNames from 'classnames';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import {HERO_BLOCK} from 'constants/blockNames';
import useWindowSize from 'hook/useWindowSize';
import {getCloudinaryObject} from 'utils/utilities';

import Header from 'components/header';
import CTAButton from 'components/ctaButton';
import StarBadge from 'components/starBadge';

import * as styles from './HeroBlock.module.scss';

const BACKGROUND_IMAGE_ORIENTATION = {
    WHOLE_SCREEN: 'WHOLE_SCREEN',
    RIGHT: 'RIGHT',
};

const MyStrengthDigitalHeroBlock = props => {
    const {
        title,
        content,
        ctaButton,
        backgroundImage,
        backgroundImageOrientation,
        mobileBackgroundImage,
        coBrandingLogo,
        coBrandingLogoWidth,
        extraTall = false,
        contactInformation,
        headerCustomization,
        starbadgeType,
    } = props;

    const {width: windowWidth, isMobile} = useWindowSize();
    const ref = useRef(null);
    const [containerHeight, setContainerHeight] = useState(null);

    useEffect(() => {
        if (ref.current) {
            setContainerHeight(ref.current.offsetHeight);
        }
    }, [ref.current]);

    let backgroundImageObj =
        isMobile && mobileBackgroundImage
            ? getCloudinaryObject(mobileBackgroundImage)
            : getCloudinaryObject(backgroundImage);
    let rootStyle = {
        backgroundImage: `url(${backgroundImageObj.url})`,
    };
    let containerStyle = {};

    // For mobile, push either image to the bottom and make the container bottom padding match the size of the image height
    if (isMobile) {
        rootStyle['backgroundSize'] = 'contain';
        rootStyle['backgroundPosition'] = 'bottom';
        containerStyle['paddingBottom'] =
            (windowWidth / backgroundImageObj.width) *
            backgroundImageObj.height;

        // For an image that stretch the whole screen, use background size 'cover'
    } else if (
        backgroundImageOrientation === BACKGROUND_IMAGE_ORIENTATION.WHOLE_SCREEN
    ) {
        rootStyle['backgroundSize'] = 'cover';

        // For an image that is right aligned, use 'contain' if the width of the container is wider then image otherwise use 'cover
    } else if (
        backgroundImageOrientation === BACKGROUND_IMAGE_ORIENTATION.RIGHT
    ) {
        rootStyle['backgroundSize'] =
            windowWidth / containerHeight >
            backgroundImageObj.width / backgroundImageObj.height
                ? 'contain'
                : 'cover';
    }

    const cobrandingLogoObj = getCloudinaryObject(coBrandingLogo);

    return (
        <>
            <div
                id={HERO_BLOCK}
                ref={ref}
                className={styles.mystrengthHeroContainer}
                style={rootStyle}
            >
                <Header
                    cobrandingLogoUrl={cobrandingLogoObj?.url}
                    cobrandingLogoAltText={cobrandingLogoObj?.name}
                    cobrandingLogoWidth={coBrandingLogoWidth}
                    customization={headerCustomization}
                    isMyStrength={true}
                />

                <div className={styles.contentItem}>
                    <h1 className={styles.title}>{renderRichText(title)}</h1>

                    <div className={styles.content}>
                        {content && <div>{renderRichText(content)}</div>}
                    </div>

                    <div>
                        {ctaButton && (
                            <CTAButton
                                id="ctaHero"
                                isMobile={isMobile}
                                {...ctaButton}
                            />
                        )}
                    </div>
                </div>
                {starbadgeType && ( 
                    <div className={classNames(styles.mystrengthBadgeItem)} alt="hero_starbadge">
                        <StarBadge {...starbadgeType} />
                    </div>
                )}
            </div>
        </>
    );
};

export default MyStrengthDigitalHeroBlock;
