import React from 'react';
import classNames from 'classnames';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import {TESTIMONIALS_BLOCK} from 'constants/blockNames';
import {getCloudinaryImageUrl} from 'utils/utilities';

import BackgroundBlock from 'components/backgroundBlock';

import * as styles from './TestimonialsBlock.module.scss';

const DPPWMTestimonialsBlock = props => {
    const {
        title,
        subtitle,
        testimonialCards,
        testimonialsDisclaimer,
        backgroundBlock,
    } = props;

    return (
        <BackgroundBlock id={TESTIMONIALS_BLOCK} {...backgroundBlock}>
            <div className={classNames(styles.root, styles.dppStyle)}>
                {title && <div className={styles.title}>{title}</div>}
                {subtitle && <div className={styles.subtitle}>{subtitle}</div>}

                <div className={classNames(styles.testimonialCards)}>
                    {testimonialCards &&
                        testimonialCards.map(testimonialCard => {
                            const userImage = getCloudinaryImageUrl(
                                testimonialCard.userImage
                            );
                            return (
                                <div
                                    key={testimonialCard.id}
                                    className={styles.cardItem}
                                >
                                    {userImage && (
                                        <img
                                            className={styles.dppMemberImage}
                                            src={userImage}
                                            alt={testimonialCard.userImage.name}
                                        />
                                    )}
                                    <div className={styles.testimonial}>
                                        {renderRichText(
                                            testimonialCard.memberTestimonial
                                        )}
                                    </div>
                                    <div className={styles.memberName}>
                                        {testimonialCard.memberName}
                                    </div>
                                </div>
                            );
                        })}
                </div>

                {testimonialsDisclaimer?.internal?.content && (
                    <div className={styles.testimonialsDisclaimer}>
                        {testimonialsDisclaimer.internal.content}
                    </div>
                )}
            </div>
        </BackgroundBlock>
    );
};

export default DPPWMTestimonialsBlock;
