import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import qs from 'qs';

import {RegCodeAllowListContext} from 'hook/RegCodeAllowListContext';
import {ctaClicked} from 'utils/mixpanel-utils';
import {convertEnvironmentLivongoLink} from 'utils/utilities';

import * as styles from './CTAButton.module.scss';

const REG_5_URL = 'https://signup.livongo.com';

const BUTTON_COLORS = {
    BLUE: 'BLUE',
    GREEN: 'GREEN',
    WHITE: 'WHITE',
    BLACK: 'BLACK',
    PURPLE: 'PURPLE',
};

const BUTTON_SIZES = {
    NORMAL: 'NORMAL',
    LARGE: 'LARGE',
};

const CUSTOMIZATIONS = {
    HTN_STYLE: 'HTN_STYLE',
    LIVONGO_COM_STYLE: 'LIVONGO_COM_STYLE',
};

const CTAButton = ({
    id,
    url,
    className,
    buttonText,
    backgroundColor = BUTTON_COLORS.GREEN,
    isBold,
    size,
    customization,
    isDPPWM = false,
    isMobile = false,
}) => {
    const {regCodeRemapping} = useContext(RegCodeAllowListContext);

    const onClickCTA = evt => {
        ctaClicked();

        // the callback redirects the user to the correct registration flow
        const gtagReportConversion = function (signinURL) {
            let callback = function () {
                if (typeof signinURL != 'undefined') {
                    // Get the URL parameters
                    let searchParamObj = qs.parse(window?.location?.search, {
                        ignoreQueryPrefix: true,
                    });
                    let isregistrationAccessCodeInParameter =
                        window?.location?.search.indexOf(
                            'registrationAccessCode='
                        ) >= 0;

                    //TODO capturing regCode to be passed to registeration flow is not working
                    let regCode =
                        searchParamObj['reg_code'] ||
                        searchParamObj['regCode'] ||
                        searchParamObj['regcode'];
                    const pathname = window?.location?.pathname;
                    const [_, ccid, customization] = pathname.split('/');

                    // Remap the regcode if found in the regCodeRemapping
                    // TODO capturing regCode to be passed to registeration flow is not working
                    if (regCodeRemapping && regCodeRemapping[regCode]) {
                        regCode = regCodeRemapping[regCode];

                        if (searchParamObj['reg_code']) {
                            searchParamObj['reg_code'] = regCode;
                        } else if (searchParamObj['regCode']) {
                            searchParamObj['regCode'] = regCode;
                        } else if (searchParamObj['regcode']) {
                            searchParamObj['regcode'] = regCode;
                        }
                    }
                    // add the ccid or customization to Registration if it exists
                    if (ccid) {
                        searchParamObj['ccid'] = ccid;
                    }
                    if (customization) {
                        searchParamObj[
                            'landing_page_customization'
                        ] = customization;
                    }

                    let newURL = signinURL;

                    if (url) {
                        newURL = url += '?' + qs.stringify(searchParamObj);
                    } else if (Object.keys(searchParamObj).length) {
                        // build the reg5Location string
                        if (isregistrationAccessCodeInParameter) {
                            newURL += '/sso?' + qs.stringify(searchParamObj);
                        } else {
                            newURL += '?' + qs.stringify(searchParamObj);
                        }
                    }

                    window.location = newURL;
                }
            };

            callback();
            return false;
        };

        gtagReportConversion(convertEnvironmentLivongoLink(REG_5_URL));
    };

    return (
        <button
            id={id}
            className={classNames(styles.ctaButton, className, {
                [styles.large]: size === BUTTON_SIZES.LARGE,
                [styles.isMobile]: isMobile,
                [styles.blue]: backgroundColor === BUTTON_COLORS.BLUE,
                [styles.green]: backgroundColor === BUTTON_COLORS.GREEN,
                [styles.white]: backgroundColor === BUTTON_COLORS.WHITE,
                [styles.black]: backgroundColor === BUTTON_COLORS.BLACK,
                [styles.purple]: backgroundColor === BUTTON_COLORS.PURPLE,
                [styles.bold]: isBold,
                [styles.htnStyle]: customization === CUSTOMIZATIONS.HTN_STYLE,
                [styles.livongoComStyle]:
                    customization === CUSTOMIZATIONS.LIVONGO_COM_STYLE,

                [styles.dppCtaButton]: isDPPWM,
            })}
            onClick={onClickCTA}
        >
            {buttonText}
        </button>
    );
};

CTAButton.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    buttonText: PropTypes.string,
    isBold: PropTypes.bool,
    size: PropTypes.string,
    customization: PropTypes.string,
    isDPPWM: PropTypes.bool,
    isMobile: PropTypes.bool,
};

export default CTAButton;
