import React, {createContext, useState, useEffect} from 'react';
import axios from 'axios';

const ALLOW_LIST_URL = 'https://mw.livongo.com/v1/redis?product=landingPage';

export const RegCodeAllowListContext = createContext();

// This context provider is passed to any component requiring the context
export const RegCodeAllowListProvider = ({children}) => {
    const [regCodeAllowList, setRegCodeAllowList] = useState({});

    // Get allow list of RegCodes
    useEffect(() => {
        const loadRegCodeAllowlist = async () => {
            try {
                const response = await axios.get(ALLOW_LIST_URL);

                if (response?.data?.regCodeRemapping) {
                    var regCodes = {
                        reg5Codes: response.data.reg5.codes,
                        regCodeRemapping: response.data.regCodeRemapping,
                    };

                    setRegCodeAllowList(regCodes);
                }
            } catch (error) {
                console.log(error);
            }
        };

        loadRegCodeAllowlist();
    }, []);

    return (
        <RegCodeAllowListContext.Provider value={regCodeAllowList}>
            {children}
        </RegCodeAllowListContext.Provider>
    );
};
