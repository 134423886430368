import React, {createContext, useState} from 'react';

export const GlobalContext = createContext();

export const GlobalProvider = ({children}) => {
    const [contactNumber, setNumber] = useState();
    const [contactPhoneURL, setContactPhoneURL] = useState();
    const physicianAttestationForm = 'https://content.livongo.com/Physician%20Attestation%20Form_Dell.docx';

    const setContactNumber = phoneNumber => {
        let formattedPhone = phoneNumber.replace(/\D/g, ''); // Digits only
        formattedPhone = `${formattedPhone[0] === '1' ? '' : '1'}${formattedPhone}}`; // add 1 to the front
        formattedPhone = formattedPhone.slice(0, 11); // Chop off to only include 1, zip code, and main 7 digits
        formattedPhone = `tel:+${formattedPhone}`; // add tel:+ href

        setNumber(phoneNumber);
        setContactPhoneURL(formattedPhone);
    };

    return (
        <GlobalContext.Provider
            value={{
                contactNumber,
                contactPhoneURL,
                physicianAttestationForm,
                setContactNumber,
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};
