import React from 'react';
import classNames from 'classnames';
import {graphql} from 'gatsby';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import {APP_BLOCK} from 'constants/blockNames';
import {ctaClicked} from 'utils/mixpanel-utils';
import BackgroundBlock from 'components/backgroundBlock';

import * as styles from './AppBlock.module.scss';

const CUSTOMIZATIONS = {
    GEN_STYLE: 'GEN_STYLE',
    MYSTRENGTH_STYLE: 'MYSTRENGTH_STYLE'
};

const AppBlock = props => {
    const {
        id,
        name,
        title,
        subtitle,
        content,
        backgroundBlock,
        ctaPlayStore,
        ctaAppStore,
        customization,
    } = props;

    const onClickBanner = url => {
        ctaClicked();

        window.open(url, '_self');
    };

    return (
        <BackgroundBlock id={APP_BLOCK} {...backgroundBlock}>
            <div
                className={classNames(styles.root, {
                    [styles.genStyle]: customization === CUSTOMIZATIONS.GEN_STYLE,
                    [styles.mystrengthStyle]: customization === CUSTOMIZATIONS.MYSTRENGTH_STYLE,
                })}
            >
                <div className={styles.context}>
                    {subtitle && (
                        <div className={styles.subtitle}>{subtitle}</div>
                    )}
                    {title && <div className={styles.title}>{title}</div>}
                    {content && (
                        <div className={styles.content}>
                            {renderRichText(content)}
                        </div>
                    )}

                    <div className={classNames(styles.btnItems)}>
                        {ctaPlayStore && (
                            <button
                                id="ctaPlayStoreBanner"
                                className={classNames(
                                    styles.ctaButton,
                                    styles.playStore
                                )}
                                onClick={evt => onClickBanner(ctaPlayStore.url)}
                                aria-label="Google Play Store app link"
                            ></button>
                        )}

                        {ctaAppStore && (
                            <button
                                id="ctaAppStoreBanner"
                                className={classNames(
                                    styles.ctaButton,
                                    styles.appStore
                                )}
                                onClick={evt => onClickBanner(ctaAppStore.url)}
                                aria-label="Apple App Store app link"
                            ></button>
                        )}
                    </div>
                </div>
            </div>
        </BackgroundBlock>
    );
};

export default AppBlock;

export const query = graphql`
    fragment AppBlock on ContentfulAppBlock {
        id
        name
        title
        subtitle
        content {
            raw
        }
        ctaAppStore {
            url
        }
        ctaPlayStore {
            url
        }
        customization
        backgroundBlock {
            ...BackgroundBlock
        }
    }
`;
