import React from 'react';
import classNames from 'classnames';
import {graphql} from 'gatsby';

import {PROGRAM_ICONS_BLOCK} from 'constants/blockNames';
import {
    getCloudinaryObject,
    convertEnvironmentLivongoLink,
} from 'utils/utilities';

import linkArrow from './images/link-arrow.png';

import * as styles from './ProgramIconsBlock.module.scss';

const ProgramIconsBlock = props => {
    const {programIcons} = props;

    return (
        <div id={PROGRAM_ICONS_BLOCK} className={classNames(styles.root)}>
            <div className={styles.container}>
                {programIcons.map(programIcon => {
                    const imageObject = getCloudinaryObject(programIcon.icon);
                    return (
                        <div
                            key={programIcon.title}
                            className={styles.programIcon}
                        >
                            <img
                                src={imageObject.url}
                                alt={programIcon.icon.name}
                                width={programIcon.iconWidth}
                            ></img>
                            {programIcon.title && (
                                <div className={styles.title}>
                                    {programIcon.title}
                                </div>
                            )}
                            {programIcon.description && (
                                <div className={styles.description}>
                                    {programIcon.description}
                                </div>
                            )}
                            {programIcon.link && (
                                <a
                                    href={convertEnvironmentLivongoLink(
                                        programIcon.link
                                    )}
                                    target="_blank"
                                >
                                    {programIcon.linkText}
                                    <img
                                        src={linkArrow}
                                        className={styles.linkArrow}
                                        width={15}
                                        height={15}
                                    />
                                </a>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ProgramIconsBlock;

export const query = graphql`
    fragment ProgramIconsBlock on ContentfulProgramIconsBlock {
        id
        name
        programIcons {
            name
            title
            description
            icon {
                ...CloudinaryImage
            }
            iconWidth
            linkText
            link
        }
    }
`;
