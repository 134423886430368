import React, {useRef, useEffect, useState} from 'react';
import classNames from 'classnames';
import {graphql} from 'gatsby';
import ReactPlayer from 'react-player';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import {videoViewed} from 'utils/mixpanel-utils';
import {VIDEO_BLOCK} from 'constants/blockNames';
import useWindowSize from 'hook/useWindowSize';

import BackgroundBlock from 'components/backgroundBlock';

import * as styles from './VideoBlock.module.scss';

const YOUTUBE_H_TO_W_RATIO = 360 / 640;

const VideoBlock = props => {
    const {
        titleRichText,
        quoteText,
        videoUrl,
        disclaimer,
        backgroundBlock,
    } = props;

    const ref = useRef(null);
    const {isMobile} = useWindowSize();
    const [containerWidth, setContainerWidth] = useState(null);

    useEffect(() => {
        if (ref.current) {
            setContainerWidth(ref.current.offsetWidth);
        }
        const handleResize = () => {
            setContainerWidth(ref.current.offsetWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [ref.current]);

    return (
        <BackgroundBlock id={VIDEO_BLOCK} {...backgroundBlock}>
            <div
                className={classNames(styles.root, {
                    [styles.isMobile]: isMobile,
                })}
            >
                {titleRichText && (
                    <div className={styles.title}>
                        {renderRichText(titleRichText)}
                    </div>
                )}
                <div className={styles.contentContainer}>
                    <div ref={ref} className={styles.videoContainer}>
                        {containerWidth && (
                            <ReactPlayer
                                url={videoUrl}
                                width={'100%'}
                                height={containerWidth * YOUTUBE_H_TO_W_RATIO}
                                onStart={videoViewed}
                            />
                        )}
                        {disclaimer && (
                            <div className={styles.disclaimer}>
                                {renderRichText(disclaimer)}
                            </div>
                        )}
                    </div>
                    {quoteText && (
                        <div className={styles.quoteContainer}>
                            {renderRichText(quoteText)}
                        </div>
                    )}
                </div>
            </div>
        </BackgroundBlock>
    );
};

export default VideoBlock;

export const query = graphql`
    fragment VideoBlock on ContentfulVideoBlock {
        name
        videoUrl
        titleRichText: title {
            raw
        }
        quoteText {
            raw
        }
        disclaimer {
            raw
        }
        backgroundBlock {
            ...BackgroundBlock
        }
    }
`;
