import React, {useState, useContext} from 'react';
import classNames from 'classnames';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import {LanguageContext} from 'hook/LanguageContext';
import {footerUrls} from 'constants/shortLinks';
import appStoreDownloadButton from './images/app-store-download-link.png';
import playStoreDownloadButton from './images/play-store-download-link.png';
import youtubeButton from './images/youtube-logo.png';
import facebookButton from './images/facebook-logo.png';
import instagramButton from './images/instagram-logo.png';

import * as styles from './Footer.module.scss';

const APP_STORE_LINK = 'https://apps.apple.com/us/app/livongo/id991595088';
const PLAY_STORE_LINK =
    'https://play.google.com/store/apps/details?id=com.livongo.lvmobility&hl=en_US&gl=US';
const FACEBOOK_LINK = 'https://www.facebook.com/livongo/';
const INSTAGRAM_LINK = 'https://www.instagram.com/livongo_health/';
const YOUTUBE_LINK = 'https://www.youtube.com/user/livongo';

const PROGRAMS = [
    {
        text: 'Diabetes',
        link: 'https://hello.livongo.com/DBT',
    },
    {
        text: 'Diabetes Prevention',
        link: 'https://hello.livongo.com/DPPWM',
    },
    {
        text: 'High Blood Pressure',
        link: 'https://hello.livongo.com/HTN',
    },
    {
        text: 'Weight Management',
        link: 'https://hello.livongo.com/DPPWM',
    },
    {
        text: 'Mental Health',
        link: 'https://www.myStrength.com',
    },
];

const ABOUT = [
    {
        text: 'Company',
        link: 'https://teladochealth.com/about/',
    },
    {
        text: 'For organizations',
        link: 'https://teladochealth.com/',
    },
    {
        text: 'For care providers',
        link: 'https://providers.teladochealth.com/',
    },
    {
        text: 'Careers',
        link: 'https://www.teladochealth.com/careers/',
    },
    {
        text: 'Investors',
        link: 'https://ir.teladochealth.com/investors/default.aspx',
    },
];

const SUPPORT = [
    {
        text: 'Contact Us',
        link: 'https://www.teladoc.com/livongo-contact/',
    },
];

const LivongoComFooter = props => {
    const {plNumber, disclaimer, memberCommunicationPolicy, ctaButton} = props;
    const {language, languageText} = useContext(LanguageContext);
    const [showPolicy, setShowPolicy] = useState(false);

    const openClosePolicy = () => {
        setShowPolicy(!showPolicy);
    };

    const MemberPolicyModal = () => (
        <div
            className={classNames(styles.modalGrayOut, {
                [styles.open]: showPolicy,
            })}
            onClick={openClosePolicy}
        >
            <div
                className={styles.memberCommunicationPolicy}
                onClick={event => {
                    // Allows for the outside container to close the modal
                    event.stopPropagation();
                }}
            >
                <div className={styles.title}>
                    {languageText['livongo-member-communication-policy']}
                    <div
                        className={styles.closeButton}
                        onClick={openClosePolicy}
                    >
                        X
                    </div>
                </div>
                {memberCommunicationPolicy ? (
                    <div className={styles.memberCommunicationPolicyBody}>
                        {renderRichText(memberCommunicationPolicy)}
                    </div>
                ) : (
                    <div>
                        {/* Fallback if we don't get the translated Member Communication Policy from Contentful*/}
                        <div>
                            By accepting this Policy, I hereby give my consent
                            for Livongo Health, Inc. (“Livongo”) to communicate
                            with me through the methods outlined in this
                            document. I understand that these communications may
                            involve the use and disclosure of protected health
                            information (“PHI”) about me to carry out treatment,
                            payment and health care operations (“TPO”). I
                            further understand that I may change my
                            communication preferences with Livongo at any time.
                        </div>

                        <br />
                        <div>Telephone</div>
                        <div>
                            With this consent, Livongo may call my home number,
                            mobile number or other alternative number and leave
                            a message on voicemail in reference to any items
                            that assist Livongo in carrying out TPO, such as
                            reminders, insurance items, shipment and delivery of
                            supplies, health profile updates and any calls
                            pertaining to my care, including, but not limited
                            to, conversations with Livongo Certified Diabetes
                            Educators.
                        </div>

                        <br />
                        <div>Text</div>
                        <div>
                            With this consent, Livongo may contact me via
                            unencrypted SMS text messages in reference to any
                            items that assist Livongo in carrying out TPO, such
                            as reminders, insurance items, shipment and delivery
                            of supplies, health profile updates and any
                            communications pertaining to my care, including text
                            messages exchanged with Livongo Certified Diabetes
                            Educators and automated text message campaigns.
                        </div>
                        <br />
                        <div>Email</div>
                        <div>
                            With this consent, Livongo may contact me via
                            unencrypted email in reference to any items that
                            assist Livongo in carrying out TPO, such as
                            reminders, insurance items, shipment and delivery of
                            supplies, health profile updates and any
                            communications pertaining to my care, including
                            information exchanged with Livongo Certified
                            Diabetes Educators and email message campaigns.
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
    return (
        <footer
            role="contentinfo"
            className={classNames(styles.footer, styles.livongoComStyle, {
                [styles.ctaButtonShowing]: ctaButton,
            })}
        >
            {/* Rainbow Border Start */}
            <table className={styles.rainbowBorder} aria-hidden="true">
                <tbody>
                    <tr>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                    </tr>
                </tbody>
            </table>
            {/* Rainbow Border End */}
            <div className={classNames(styles.container)}>
                <div className={styles.linkContainer}>
                    <div className={styles.linkColumn}>
                        <div className={styles.subtitle}>Learn More</div>
                        {PROGRAMS.map(linkObj => (
                            <a
                                key={linkObj.text}
                                className={styles.footerColumnLink}
                                href={linkObj.link}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {linkObj.text}
                            </a>
                        ))}
                    </div>
                    <div className={styles.linkColumn}>
                        <div className={styles.subtitle}>About</div>
                        {ABOUT.map(linkObj => (
                            <a
                                key={linkObj.text}
                                className={styles.footerColumnLink}
                                href={linkObj.link}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {linkObj.text}
                            </a>
                        ))}
                    </div>
                    <div className={styles.linkColumn}>
                        <div className={styles.subtitle}>Support</div>
                        {SUPPORT.map(linkObj => (
                            <a
                                key={linkObj.text}
                                className={styles.footerColumnLink}
                                href={linkObj.link}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {linkObj.text}
                            </a>
                        ))}
                    </div>
                    <div className={styles.linkColumn}>
                        <div className={styles.subtitle}>Connect with us</div>
                        <div className={styles.socialLinkContainer}>
                            <div className={styles.row}>
                                <a href={APP_STORE_LINK} target="_blank">
                                    <img
                                        src={appStoreDownloadButton}
                                        className={styles.rightSpacer}
                                        height={34}
                                        width={100}
                                        aria-label="Apple App Store app link"
                                    />
                                </a>

                                <a href={PLAY_STORE_LINK} target="_blank">
                                    <img
                                        src={playStoreDownloadButton}
                                        height={34}
                                        width={100}
                                        aria-label="Google Play Store app link"
                                    />
                                </a>
                            </div>
                            <div
                                className={classNames(
                                    styles.row,
                                    styles.socialMediaRow
                                )}
                            >
                                <a href={FACEBOOK_LINK} target="_blank">
                                    <img
                                        src={facebookButton}
                                        className={styles.iconSpacer}
                                        width={50}
                                        aria-label="Facebook page link"
                                    />
                                </a>
                                <a href={INSTAGRAM_LINK} target="_blank">
                                    <img
                                        src={instagramButton}
                                        className={styles.iconSpacer}
                                        width={50}
                                        aria-label="Instagram page link"
                                    />
                                </a>

                                <a href={YOUTUBE_LINK} target="_blank">
                                    <img
                                        src={youtubeButton}
                                        className={styles.iconSpacer}
                                        width={50}
                                        aria-label="Youtube page link"
                                    />
                                </a>
                            </div>
                        </div>
                        {disclaimer && (
                            <div className={styles.disclaimer}>
                                {renderRichText(disclaimer)}
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles.flexContainer}>
                    <div className={styles.flexLinkContainer}>
                        {footerUrls.map((obj, i) => {
                            return (
                                <a
                                    key={i}
                                    className={styles.link}
                                    href={obj[language]}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {languageText[obj.name]}
                                </a>
                            );
                        })}
                    </div>
                    <div>
                        <>
                            <span>
                                {
                                    languageText[
                                        'year-at-teladoc-all-rights-reserved'
                                    ]
                                }{' '}
                                {plNumber}
                            </span>
                        </>
                    </div>
                </div>

                <MemberPolicyModal />
            </div>
        </footer>
    );
};

export default LivongoComFooter;
