import React from 'react';
import classNames from 'classnames';
import {graphql} from 'gatsby';

import {SUPPLY_BLOCK} from 'constants/blockNames';
import useWindowSize from 'hook/useWindowSize';

import CTAButton from 'components/ctaButton';
import BackgroundBlock from 'components/backgroundBlock';

import * as styles from './SupplyBlock.module.scss';

const SupplyBlock = props => {
    const {
        title,
        subtitle,
        contentBasicText,
        ctaButton,
        backgroundBlock,
    } = props;

    const {isMobile} = useWindowSize();

    return (
        <BackgroundBlock id={SUPPLY_BLOCK} {...backgroundBlock}>
            <div className={classNames(styles.root)}>
                {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
                {title && <div className={styles.title}>{title}</div>}
                <div
                    className={classNames(styles.content, {
                        [styles.hasButtonBelow]: !!ctaButton,
                    })}
                >
                    {contentBasicText}
                </div>
                {ctaButton && (
                    <CTAButton
                        id="ctaButton"
                        isBold
                        isMobile={isMobile}
                        {...ctaButton}
                    ></CTAButton>
                )}
            </div>
        </BackgroundBlock>
    );
};

export default SupplyBlock;

// Had to use an alias with content because it was conflicting type with other content on other blocks, should probably convert to Rich Text Type
export const query = graphql`
    fragment SupplyBlock on ContentfulSupplyBlock {
        id
        name
        title
        subtitle
        contentBasicText: content
        ctaButton {
            ...CTAButton
        }
        backgroundBlock {
            ...BackgroundBlock
        }
    }
`;
