import React, {useContext} from 'react';
import classNames from 'classnames';
import htmlStringReactParser from 'html-react-parser';

import {FAQ_BLOCK} from 'constants/blockNames';
import {GlobalContext} from 'hook/GlobalContext';
import {LanguageContext} from 'hook/LanguageContext';
import useWindowSize from 'hook/useWindowSize';
import {phoneNumberClicked} from 'utils/mixpanel-utils';

import BackgroundBlock from 'components/backgroundBlock';

import * as styles from './FAQBlock.module.scss';

const MedicareAdvantageFAQBlock = props => {
    const {
        subtitle,
        title,
        columns = 2,
        questions,
        options,
        backgroundBlock,
    } = props;

    const {isMobile} = useWindowSize();
    const {languageText} = useContext(LanguageContext);
    const {contactNumber, contactPhoneURL} = useContext(GlobalContext);

    const onClickPhone = () => {
        phoneNumberClicked();
    };

    const renderQuestion = question => (
        <div key={question.id} className={classNames(styles.questionSection)}>
            <div className={classNames(styles.question)}>
                {question.question.question}
            </div>
            <div className={classNames(styles.answer)}>
                {htmlStringReactParser(
                    question.answer.childMarkdownRemark.html,
                    options
                )}
            </div>
        </div>
    );

    const renderBySize = isMobile => {
        if (isMobile || columns === 1) {
            return (
                <div
                    className={classNames(
                        styles.leftRightContainer,
                        styles.singleColumn
                    )}
                >
                    <div className={styles.questionsSection}>
                        {questions &&
                            questions.map((question, i) =>
                                renderQuestion(question)
                            )}
                    </div>
                </div>
            );
        } else {
            return (
                <div className={classNames(styles.leftRightContainer)}>
                    <div className={styles.questionsSection}>
                        {questions &&
                            questions.map((question, i) =>
                                i % 2 === 0 ? renderQuestion(question) : null
                            )}
                    </div>
                    <div className={styles.questionsSection}>
                        {questions &&
                            questions.map((question, i) =>
                                i % 2 === 1 ? renderQuestion(question) : null
                            )}
                    </div>
                </div>
            );
        }
    };

    return (
        <BackgroundBlock id={FAQ_BLOCK} {...backgroundBlock}>
            <div
                className={classNames(
                    styles.root,
                    styles.medicareAdvantageStyle
                )}
            >
                {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
                {title && <div className={styles.title}>{title}</div>}
                {renderBySize(isMobile)}
                <div className={styles.additionalQuestions}>
                    {languageText['have-addition-questions-question-mark']}
                </div>

                <button className={styles.numberButton} onClick={onClickPhone}>
                    <a href={contactPhoneURL}>
                        <strong>{contactNumber}</strong>
                    </a>
                </button>
            </div>
        </BackgroundBlock>
    );
};

export default MedicareAdvantageFAQBlock;
