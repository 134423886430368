import React, {useContext} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {LanguageContext} from 'hook/LanguageContext';
import {GlobalContext} from 'hook/GlobalContext';
import {convertEnvironmentLivongoLink} from 'utils/utilities';
import {
    signInClicked,
    phoneNumberClicked,
    translationClicked,
} from 'utils/mixpanel-utils';

import DPPWMHeader from './DPPWMHeader';
import STEPHeader from './STEPHeader';
import LivongoComHeader from './LivongoComHeader';
import MyStrengthHeader from './MyStrengthHeader';
import WPHFHeader from './WPHFHeader';

import * as styles from './Header.module.scss';

const LIVONGO_LOGO =
    'https://assets.livongo.com/image/upload/v1641508544/landing-pages/header-block/livongoTH_logo.png';
const AMERICAN_ENGLISH = 'en-US';
const AMERICAN_SPANISH = 'es-US';

const CUSTOMIZATIONS = {
    STEP_CUSTOMIZATION: 'STEP_CUSTOMIZATION',
    LIVONGO_COM_CUSTOMIZATION: 'LIVONGO_COM_CUSTOMIZATION',
    NO_LIVONGO_LOGO: 'NO_LIVONGO_LOGO',
    CLIENT_LOGO_ON_LEFT: 'CLIENT_LOGO_ON_LEFT',
    CLIENT_LOGO_ON_LEFT_WITH_VERTICAL_LINE:
        'CLIENT_LOGO_ON_LEFT_WITH_VERTICAL_LINE',
    CLIENT_LOGO_ON_RIGHT: 'CLIENT_LOGO_ON_RIGHT',
    CLIENT_LOGO_ON_RIGHT_WITH_VERTICAL_LINE:
        'CLIENT_LOGO_ON_RIGHT_WITH_VERTICAL_LINE',
    CLIENT_LOGO_ON_RIGHT_WITH_VERTICAL_LINE:
        'CLIENT_LOGO_ON_RIGHT_WITH_VERTICAL_LINE',
};

const Header = props => {
    const {
        cobrandingLogoUrl,
        cobrandingLogoAltText,
        cobrandingLogoWidth = 100,
        customization,
        secondCobrandingLogoUrl,
        secondCobrandingAltText,
        isDPPWM,
        isMyStrength,
        isMobile,
        isWPHF,
    } = props;

    const {
        availableLanguages,
        language,
        languageText,
        updateCurrentLang,
    } = useContext(LanguageContext);
    const loginURL = convertEnvironmentLivongoLink(process.env.LOGIN_URL);
    const {contactNumber, contactPhoneURL} = useContext(GlobalContext);

    if (CUSTOMIZATIONS.STEP_CUSTOMIZATION === customization && isMobile) {
        return <STEPHeader {...props} />;
    } else if (isDPPWM) {
        return <DPPWMHeader {...props} />;
    } else if (CUSTOMIZATIONS.LIVONGO_COM_CUSTOMIZATION === customization) {
        return <LivongoComHeader {...props} />;
    } else if (isMyStrength) {
        return <MyStrengthHeader {...props} />;
    } else if (isWPHF) {
        return <WPHFHeader {...props} />;
    }

    const onClickSignIn = () => {
        signInClicked();

        window.location = loginURL;
    };

    const onClickTranslate = () => {
        translationClicked();

        updateCurrentLang(
            language === AMERICAN_ENGLISH ? AMERICAN_SPANISH : AMERICAN_ENGLISH
        );
    };

    const onClickPhone = () => {
        phoneNumberClicked();
    };

    return (
        <header className={styles.header}>
            <div
                className={classNames(styles.logoContainer, {
                    [styles.clientLogoOnRight]:
                        customization === CUSTOMIZATIONS.CLIENT_LOGO_ON_RIGHT ||
                        customization ===
                            CUSTOMIZATIONS.CLIENT_LOGO_ON_RIGHT_WITH_VERTICAL_LINE,
                })}
            >
                {cobrandingLogoUrl && (
                    <img
                        className={styles.coBrandingLogo}
                        src={cobrandingLogoUrl}
                        alt={cobrandingLogoAltText}
                    ></img>
                )}
                {cobrandingLogoUrl && (
                    <div
                        className={classNames(styles.logoPipe, {
                            [styles.notInvisible]:
                                customization ===
                                    CUSTOMIZATIONS.CLIENT_LOGO_ON_LEFT_WITH_VERTICAL_LINE ||
                                customization ===
                                    CUSTOMIZATIONS.CLIENT_LOGO_ON_RIGHT_WITH_VERTICAL_LINE,
                        })}
                    ></div>
                )}
                {customization !== CUSTOMIZATIONS.NO_LIVONGO_LOGO && (
                    <img
                        className={styles.logo}
                        src={LIVONGO_LOGO}
                        alt="Livongo Logo"
                    />
                )}
            </div>

            <div className={classNames(styles.rightSide)}>
                {contactNumber && (
                    <div className={styles.phoneContact} onClick={onClickPhone}>
                        <a href={contactPhoneURL}>
                            {isMobile ? languageText['call'] : contactNumber}
                        </a>
                    </div>
                )}

                {availableLanguages.length > 1 && (
                    <div
                        className={styles.changeLanguage}
                        onClick={onClickTranslate}
                    >
                        <a>
                            {language === AMERICAN_ENGLISH
                                ? 'EN ESPAÑOL'
                                : 'IN ENGLISH'}
                        </a>
                    </div>
                )}

                <button className={styles.signInButton} onClick={onClickSignIn}>
                    {languageText['sign-in']}
                </button>
            </div>
        </header>
    );
};

Header.propTypes = {
    cobrandingLogoUrl: PropTypes.string,
    cobrandingLogoAltText: PropTypes.string,
    cobrandingLogoWidth: PropTypes.number,
    secondCobrandingLogoUrl: PropTypes.string,
    isDPPWM: PropTypes.bool,
    isMyStrength: PropTypes.bool,
};

Header.defaultProps = {
    isDPPWM: false,
    isMyStrength: false,
};

export default Header;
